import { useState } from 'react';
import { constants } from '../../helpers/';
import { AvatarList, ScrollbarWrapper } from '../../components/common/';
import './OrganizationDetails.scss';
import { noTechAssign } from '../../assets/images';
// @ts-ignore
import TechnicianViewAllModal from './TechnicianViewAllModal.tsx';

const MAX_DISPLAY_TECHNICIANS = 5;

const AssignedTechnicians = ({ installerUsers }: { installerUsers: any[] }) => {
  const [showViewAll, setShowViewAll] = useState(false);

  return (
    <div className="section-technicians">
      <div className="technicians-title-area">
        <div className="label-name">
          {constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE}
        </div>
        {installerUsers && installerUsers.length > MAX_DISPLAY_TECHNICIANS && (
          <div
            className="view-all-button"
            role="button"
            onClick={() => setShowViewAll(true)}
          >
            {constants.ASSIGN_TECHNICIANS_VIEW_ALL_BUTTON_TITLE}
          </div>
        )}
      </div>
      {installerUsers?.length > 0 ? (
        <div className="technicians-list">
          <div>
            <ScrollbarWrapper height={200}>
              {installerUsers
                ?.slice(0, MAX_DISPLAY_TECHNICIANS)
                .map((user, index) => {
                  let profileImg = user?.image?.url;
                  let firstName = user?.firstName?.charAt(0).toUpperCase();
                  let lastName = user?.lastName?.charAt(0).toUpperCase();
                  let letter = firstName + lastName;
                  return (
                    <div key={user?.accountId}>
                      <AvatarList
                        avatarValueType={profileImg ? 'icon' : 'text'}
                        avatarValue={profileImg ? profileImg : letter}
                        title={`${user?.firstName} ${user?.lastName}`}
                      />
                    </div>
                  );
                })}
            </ScrollbarWrapper>
          </div>
        </div>
      ) : (
        <div className="no-technician">
          <div className="bg-no-tech">
            <img
              className="img-no-tech"
              src={noTechAssign}
              alt="no technitions"
            />
          </div>
          <div className="label-no-tech">
            {constants.NO_ASSIGN_TECHNICIANS_FOUND_TITLE}
          </div>
        </div>
      )}
      {showViewAll && (
        <TechnicianViewAllModal
          showModal={showViewAll}
          hideModal={() => setShowViewAll(false)}
          technicianList={installerUsers}
        />
      )}
    </div>
  );
};

export default AssignedTechnicians;
