import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays, isSunday } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DateRangePickerWithLabels.scss";

const DateRangePickerWithLabels = ({ passedDateRange, okBtnClick }) => {
  const normalizeDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(passedDateRange.startDate),
      endDate: new Date(passedDateRange.endDate),
      key: "selection",
      color: "#f37321",
      label: "Primary Range",
    },
  ]);

  const dateRangeOptions = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "Custom",
      range: () => ({
        startDate: addDays(new Date(), -3),
        endDate: new Date(),
      }),
      isSelected(range) {
        return false;
      },
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: addDays(new Date(), -7),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: addDays(new Date(), -30),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "Last 90 Days",
      range: () => ({
        startDate: addDays(new Date(), -90),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "After 7 Days",
      range: () => ({
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "After 30 Days",
      range: () => ({
        startDate: new Date(),
        endDate: addDays(new Date(), 30),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "After 90 Days",
      range: () => ({
        startDate: new Date(),
        endDate: addDays(new Date(), 90),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "This Week",
      range: () => {
        const now = new Date();
        const startOfWeek = addDays(now, -now.getDay());
        const endOfWeek = addDays(startOfWeek, 6);
        return {
          startDate: startOfWeek,
          endDate: endOfWeek,
        };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
    {
      label: "This Month",
      range: () => {
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return {
          startDate: startOfMonth,
          endDate: endOfMonth,
        };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          normalizeDate(range.startDate) ===
            normalizeDate(definedRange.startDate) &&
          normalizeDate(range.endDate) === normalizeDate(definedRange.endDate)
        );
      },
    },
  ];

  const handleSelect = (ranges) => {
    const normalizedRange = {
      startDate: new Date(normalizeDate(ranges.selection.startDate)),
      endDate: new Date(normalizeDate(ranges.selection.endDate)),
    };

    setSelectedDateRange([
      {
        ...normalizedRange,
        key: "selection",
        color: "#f37321",
        label: "Primary Range",
      },
    ]);
  };

  const handleOkClick = () => {
    okBtnClick(selectedDateRange[0]);
  };

  const renderDayContent = (day) => {
    const custom_class = isSunday(day) ? "date-red" : "";
    return <span className={custom_class}>{day.getDate()}</span>;
  };

  return (
    <div className="date-range-picker-container shadow-lg rounded-lg">
      <DateRangePicker
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={selectedDateRange}
        direction="horizontal"
        staticRanges={dateRangeOptions}
        inputRanges={[]}
        dayContentRenderer={renderDayContent}
      />
      <div className="range-btn-container">
        <button onClick={handleOkClick} className="ok-btn">
          OK
        </button>
      </div>
    </div>
  );
};

export default DateRangePickerWithLabels;
