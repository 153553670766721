import { constants } from '../../helpers/';
import SiteModal from '../../components/common/SiteModal';
import './OrganizationDetails.scss';

type TechnicianItem = {
  accountId: string;
  accountLanguage: string;
  accountStatus: string;
  activeDate: number;
  createdDate: number;
  disableDate: number;
  email: string;
  firstName: string;
  image: { url: string; expiry: number };
  isLocked: boolean;
  lastName: string;
  name: string;
  phoneNumber: string;
  role: string;
  sessionTimeout: number;
  updatedDate: number;
};

const TechnicianViewAllModal = ({
  showModal,
  hideModal,
  technicianList,
}: {
  showModal: boolean;
  hideModal: () => void;
  technicianList: TechnicianItem[];
}) => {
  return (
    <SiteModal
      modalTitle={constants.ASSIGN_TECHNICIANS_LIST_MODAL_TITLE}
      showModal={showModal}
      hideModal={hideModal}
      classes={'technician-view-all-modal'}
      errorMsg={'Error'}
      wrapperClass={''}
      size={647}
    >
      <div className="technician-modal-area">
        <ul className="technician-list">
          {technicianList.map((item) => (
            <li className="technician-list-item">
              <span className="technician-img-area">
                <img src={item.image.url} alt={item.name} />
              </span>
              <span className="technician-name">{item.name}</span>
            </li>
          ))}
        </ul>
      </div>
    </SiteModal>
  );
};

export default TechnicianViewAllModal;
