import React, { useEffect, useRef, useState } from 'react';
import ColumnResizer from 'column-resizer';
import clsx from 'clsx';
import { Table } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import { constants } from '../../../helpers';
import './ResizableTable.scss';

const ResizableTable = ({
  widths,
  minWidths,
  minWidth,
  disabledColumns,
  displayHeaderDetails,
  displayGatewayDetails,
  totalPages,
  totalElements,
  pageDetails,
  showPagination = false,
  onchangePageNo,
  license = false,
}) => {
  const tableRef = useRef(null);
  const [resize, setResize] = useState();
  const [resizable, setResizable] = useState(true);
  const [gotoPage, setGotoPage] = useState('');

  const onRealease = (e) => {
    var gripHandles = e.currentTarget.getElementsByClassName('grip-handle');
    var gripHandleList = Array.prototype.slice.call(gripHandles);

    gripHandleList.forEach((ele) => {
      ele.t.opt.minWidth = minWidth;
    });
  };

  const onDrag = (e) => {
    var gripDrags = e.currentTarget.getElementsByClassName('grip-drag');
    var gripDragsList = Array.prototype.slice.call(gripDrags);
    const first = minWidths[gripDragsList[0].i];
    const last = minWidths[gripDragsList[0].i + 1];
    var gripHandles = document.getElementsByClassName('grip-handle');
    var gripHandleList = Array.prototype.slice.call(gripHandles);

    if (gripDragsList[0].l >= gripDragsList[0].x) {
      gripDragsList[0].t.opt.minWidth = first;
      gripHandleList[gripDragsList[0].i].t.opt.minWidth = first;
    } else {
      gripDragsList[0].t.opt.minWidth = last;
      gripHandleList[gripDragsList[0].i].t.opt.minWidth = last;
    }
  };

  const [resizeOptions, setResizeOptions] = useState({
    liveDrag: true,
    widths: widths,
    minWidth: minWidth,
    disabledColumns: disabledColumns,
    hoverCursor: 'e-resize',
    dragCursor: 'e-resize',
    onResize: onRealease,
    onDrag: onDrag,
  });

  const enableResize = () => {
    if (!resize) {
      const resizeFn = new ColumnResizer(tableRef.current, resizeOptions);
      tableRef.current.className = tableRef.current?.className?.replace(
        'grip-padding',
        '',
      );
      setResize(resizeFn);
      resizeFn.onResize();
    } else {
      resize?.reset(resizeOptions);
    }
  };

  const disableResize = () => {
    if (!!resize) {
      resize?.reset({ disable: true });
    }
  };

  useEffect(() => {
    if (tableRef.current && resizable) {
      enableResize();
    }
  }, [tableRef]);

  useEffect(() => {
    return disableResize();
  }, []);

  useEffect(() => {
    setGotoPage('');
  }, [pageDetails]);

  return (
    <>
      <Table className="resizable-table" responsive ref={tableRef}>
        <thead>{displayHeaderDetails()}</thead>
        <tbody>{displayGatewayDetails()}</tbody>
      </Table>
      {showPagination ? (
        <div className="pagination-container">
          <div className="total">
            <div>{constants.DEVICES_TABLE_PAGINATION_TOTAL}</div>
            <div>{totalElements}</div>
          </div>
          <Pagination
            count={totalPages}
            shape="rounded"
            onChange={(e, value) => onchangePageNo(value - 1)}
            page={pageDetails?.page + 1}
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: license
                  ? `var(--dark_orange)`
                  : `var(--brand_primary)`,
              },
            }}
          />
          <div className="go-to-page">
            <div>{constants.DEVICES_TABLE_PAGINATION_GO_TO_PAGE}</div>
            <div className={`input-page`}>
              <input
                type="number"
                min="1"
                className="page-input"
                value={gotoPage}
                onChange={(e) => setGotoPage(e?.target?.value)}
                onKeyDown={(e) => {
                  if (
                    e.key === 'Enter' &&
                    gotoPage &&
                    Number(gotoPage) &&
                    Number(gotoPage) <= totalPages
                  ) {
                    onchangePageNo(Number(gotoPage) - 1);
                    setGotoPage('');
                    e.target.blur();
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default ResizableTable;
