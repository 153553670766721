import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    MenuItem,
    Avatar,
    FormControl,
    Typography,
    OutlinedInput,
    Popper,
    Paper,
    ClickAwayListener,
    IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './CustomSelect.scss';

const CustomSelect = ({
    options,
    name,
    defaultOptionLabel = 'Location Contact',
    className = '',
    avatarSize = 24,
    disabled = false,
}) => {
    const [filterText, setFilterText] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [open, setOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const inputRef = useRef(null);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setFilterText(value);

        const newFilteredOptions = options?.filter((option) =>
            option.label.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredOptions(newFilteredOptions);

        if (!open) setOpen(true);
    };

    const handleOptionClick = (value) => {
        const selected = options.find((option) => option.value === value);
        setSelectedOption(selected);
        setFilterText(selected?.label || '');
        setOpen(false);
    };

    const handleInputClick = () => {
        setIsFocused(true);
        setOpen(true);
        setFilteredOptions(options);
    };

    const handleClearInput = () => {
        setSelectedOption(null);
        setFilterText('');
        setFilteredOptions(options);
        setIsFocused(false);
    };

    const handleBlur = (event) => {
        // Prevent blur if clicking inside the dropdown
        if (
            event.relatedTarget &&
            inputRef.current &&
            inputRef.current.contains(event.relatedTarget)
        ) {
            return;
        }
        setIsFocused(false);
        setOpen(false);
    };

    const getInitials = (label) => {
        if (!label) return '';
        const words = label.trim().split(/\s+/);
        return words
            .map((word) => word[0])
            .slice(0, 2)
            .join('')
            .toUpperCase();
    };

    const getDynamicColor = (label) => {
        const colors = [
            '#FF5733',
            '#33FF57',
            '#3357FF',
            '#F333FF',
            '#FFC133',
            '#33FFF5',
            '#8A33FF',
            '#FF3360',
            '#33FF8A',
            '#FF8A33',
        ];
        const hash = label
            .split('')
            .reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[hash % colors.length];
    };
    return (
        <FormControl fullWidth className={`custom-select-container ${className}`}>
            <OutlinedInput
                value={filterText}
                onChange={handleInputChange}
                onClick={handleInputClick}
                onBlur={handleBlur}
                placeholder={!selectedOption ? defaultOptionLabel : ''}
                disabled={disabled}
                endAdornment={
                    <>
                        {isFocused && !filterText && (
                            <IconButton edge="end" size="small">
                                <SearchIcon />
                            </IconButton>
                        )}
                        {filterText && (
                            <IconButton onClick={handleClearInput} edge="end" size="small">
                                <CloseIcon />
                            </IconButton>
                        )}
                        {!isFocused && !filterText && (
                            <IconButton edge="end" size="small">
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        )}
                    </>
                }
                inputRef={inputRef}
                sx={{
                    height: '40px',
                    borderRadius: '12px',
                    padding: '16px 3px',
                    fontSize: '0.875rem',
                }}
            />

            {open && (
                <Popper
                    open={open}
                    anchorEl={inputRef.current}
                    placement="bottom-start"
                    style={{
                        zIndex: 1300,
                        // width: inputRef.current ? inputRef.current.offsetWidth : 'auto',
                        width: inputRef.current && inputRef.current.offsetWidth <= 485 ? '485px' : 'auto',
                    }}
                >
                    <ClickAwayListener onClickAway={handleBlur}>
                        <Paper sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option) => {
                                    const parts = option.label.split(
                                        new RegExp(`(${filterText})`, 'gi'),
                                    );

                                    return (
                                        <MenuItem
                                            key={option.value}
                                            onClick={() => handleOptionClick(option.value)}
                                            onMouseDown={(e) => e.preventDefault()} // Prevent triggering blur
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                fontSize: '14px',
                                            }}
                                        >
                                            {option.avatar ? (
                                                <Avatar
                                                    src={option.avatar}
                                                    sx={{
                                                        width: avatarSize,
                                                        height: avatarSize,
                                                    }}
                                                />
                                            ) : (
                                                <Avatar
                                                    sx={{
                                                        width: avatarSize,
                                                        height: avatarSize,
                                                        backgroundColor: getDynamicColor(option.label),
                                                        fontSize: `${avatarSize * 0.5}px`,
                                                    }}
                                                >
                                                    {getInitials(option.label)}
                                                </Avatar>
                                            )}
                                            <Typography sx={{ fontSize: '14px' }}>
                                                {parts.map((part, index) =>
                                                    part.toLowerCase() === filterText.toLowerCase() ? (
                                                        <span
                                                            key={index}
                                                            style={{ color: '#c25c1a', fontWeight: 'bold' }}
                                                        >
                                                            {part}
                                                        </span>
                                                    ) : (
                                                        part
                                                    ),
                                                )}
                                            </Typography>
                                        </MenuItem>
                                    );
                                })
                            ) : (
                                <MenuItem disabled>
                                    <Typography>No results found</Typography>
                                </MenuItem>
                            )}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            )}
        </FormControl>
    );
};

CustomSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            label: PropTypes.string.isRequired,
            avatar: PropTypes.string,
        }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    defaultOptionLabel: PropTypes.string,
    className: PropTypes.string,
    avatarSize: PropTypes.number,
    disabled: PropTypes.bool,
};

export default CustomSelect;
