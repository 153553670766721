import SiteSpinner from '../../../components/common/SiteSpinner';

const DeviceEditInputBox = ({
  deviceName,
  isRenamingDevice,
  onRenameDevice,
}: {
  deviceName: string;
  isRenamingDevice: boolean;
  onRenameDevice: (value: string) => void;
}) => {
  const onFinishEditing = (inputValue: string) => {
    onRenameDevice(inputValue === deviceName ? null : inputValue);
  };
  return (
    <span className="edit-name-container">
      <input
        autoFocus={true}
        maxLength={40}
        type={'search'}
        placeholder={'Name'}
        className={`rename-input ${isRenamingDevice ? 'renaming' : ''}`}
        defaultValue={deviceName}
        onBlur={(e) => {
          onFinishEditing(e?.target?.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onFinishEditing(e?.currentTarget?.value);
          }
        }}
      />

      {isRenamingDevice && (
        <SiteSpinner
          width="16px"
          height="16px"
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--brand_black')}
        />
      )}
    </span>
  );
};

export default DeviceEditInputBox;
