import { useState, useEffect, useCallback, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import {
  Header,
  BreadcrumbList,
  SiteModal,
  SiteSpinner,
} from '../../../components/common';
import { constants, Utils, DeviceTypeEnum } from '../../../helpers/';
import LoadingOverlay from 'react-loading-overlay';
import { getLocationsData } from '../../../store/reducers/AccountReducer';
import { useSelector } from 'react-redux';
import { IoIosSearch } from 'react-icons/io';
import './Devices.scss';
import { getSelectedOrganization } from '../../../store/AccountStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../store/reducers/OrganizationsReducer';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { RxCaretSort } from 'react-icons/rx';
import { TfiReload } from 'react-icons/tfi';
import { CiFilter } from 'react-icons/ci';
import { BsPlus } from 'react-icons/bs';
import { IoCloseCircle } from 'react-icons/io5';
import {
  HiOutlineChevronDown,
  HiOutlineChevronRight,
  HiOutlineChevronLeft,
} from 'react-icons/hi';
import { CircleExclamation } from '../../../assets/images';
import { sassTrue } from 'sass';

import { ReactComponent as StepBottomIcon } from '../../../assets/images/step_bottom.svg';
import ResizableTable from './ResizableTable';
import DevicesFilters from './DevicesFilters';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import RemoveDeviceModal from './RemoveDeviceModal';
import DateTimePicker from '../../../components/common/DateTimePicker';
import moment from 'moment/moment';
import {
  addAllAnnouncedDevicesAPI,
  addAnnouncedDevicesAPI,
  announcedDevicesAPI,
  fetchDevicesBysNOMacAPI,
  getDeviceTypesAPI,
  reclaimAnnouncedDevicesAPI,
  removeAnnouncedDevicesAPI,
  updateAnnouncedDevicesAPI,
} from './DevicesAPI';
import DiscoveredDevicesRegister from './DiscoveredDevicesRegister';
import highlightWords from 'highlight-words';

const DiscoveredDevices = () => {
  const [orgDetails, setOrgDetails] = useState();
  const orgInfo = useSelector(getOrgInfo);
  const [filteredDeviceData, setFilteredDeviceData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const navigate = useNavigate();
  const locationsData = useSelector(getLocationsData);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [isDisplayFiltersModal, setIsDisplayFiltersModal] = useState(false);
  const [renamedDeviceName, setRenamedDeviceName] = useState(null);
  const [isDisplayLocationAreaModal, setIsDisplayLocationAreaModal] =
    useState(false);
  const [isDisplayRemoveDeviceModal, setIsDisplayRemoveDeviceModal] =
    useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isD2CInSelectedDevices, setIsD2CInSelectedDevices] = useState(false);
  const [isToggleClaim, setIsToggleClaim] = useState(true);
  const [selectEligibleDevices, setSelectEligibleDevices] = useState([]);
  const [isDisplayDateRangeModal, setIsDisplayDateRangeModal] = useState(false);
  const [renamingDevice, setRenamingDevice] = useState(null);
  const [isRenamingDevice, setIsRenamingDevice] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [expandedParent, setExpandedParent] = useState(null);
  const [isCheckForSearchCSS, setIsCheckForSearchCSS] = useState(false);
  const [totalDevices, setTotalDevices] = useState(0);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [isAddAllSelected, setIsAddAllSelected] = useState(false);
  const renameInputRef = useRef();

  const defaultFilters = {
    connectType: [],
    connectionStatus: [],
    deviceStatus: [],
    firmwareUpdate: [],
    applicationsLinked: [],
  };
  const defaultPageDetails = {
    orderBy: 'deviceName',
    isAsc: true,
    page: 0,
    size: 100,
  };
  const childNewPageDetails = {
    orderBy: 'deviceName',
    isAsc: true,
    page: -1,
    size: 8,
  };
  const [pageDetails, setPageDetails] = useState(defaultPageDetails);
  const [filtersData, setFiltersData] = useState(defaultFilters);
  const today = new Date();
  const monthStart = new Date();
  monthStart.setHours(0, 0, 0, 0);
  monthStart.setDate(today.getDate() - 30);

  const todayNight = new Date();
  todayNight.setHours(23, 59, 59, 0);

  const defaultDate = {
    startDate: moment(new Date(monthStart).getTime()).format('MMM DD, YYYY'),
    endDate: moment(new Date(todayNight).getTime()).format('MMM DD, YYYY'),
  };

  const [filterDate, setFilterDate] = useState(defaultDate);
  const addOptions = ['Add selected devices', 'Add all devices'];

  const breadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)?.url,
      title: Utils.CheckLoggedInUserRole(orgInfo?.orgId, orgInfo?.orgName)
        ?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgInfo?.orgId}&orgName=${orgInfo?.orgName}&fromPush=true`,
      title: `${orgInfo?.orgName}`,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
    {
      url: `/devices/discovered-devices.html?orgId=${orgInfo?.orgId}`,
      title: constants.DISCOVERED_DEVICES_SETUP_BUTTON,
    },
  ];

  const paginatedHeaderList = [
    {
      displayName: '',
      id: 'arrow',
    },
    {
      displayName: '',
      id: 'checkbox',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_NAME,
      id: 'deviceName',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_MODEL,
      id: 'modelNumber',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_SN,
      id: 'serialNo',
    },
    {
      displayName: 'IP',
      id: 'ipAddress',
    },
    {
      displayName: constants.DEVICES_TABLE_COLUMN_STATUS,
      id: 'connectionStatus',
    },
    {
      displayName: 'Date added',
      id: 'createdDate',
    },
  ];

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    fetchDiscoveredDevices(searchText);
  }, [
    JSON.stringify(pageDetails),
    JSON.stringify(filtersData),
    JSON.stringify(filterDate),
  ]);

  useEffect(() => {
    fetchDeviceTypes();
  }, []);

  const outsideClickRenameBox = (event) => {
    const editContainer = document.getElementsByClassName(
      'edit-name-container',
    )?.[0];
    if (
      !editContainer?.contains(event?.target) &&
      renameInputRef?.current?.value
    ) {
      window?.removeEventListener('click', outsideClickRenameBox);
      onRenameDevice(renameInputRef?.current?.value);
    }
  };

  useEffect(() => {
    if (renamingDevice?.deviceId) {
      window?.addEventListener('click', outsideClickRenameBox);
    } else {
      window?.removeEventListener('click', outsideClickRenameBox);
    }

    return () => {
      window?.removeEventListener('click', outsideClickRenameBox);
    };
  }, [renamingDevice?.deviceId]);

  useEffect(() => {
    const list = [];
    filteredDeviceData?.forEach((device) => {
      if (
        device &&
        device.connectionStatus !==
          constants.DEVICES_OFFLINE_CONNECTION_STATUS &&
        ((isToggleClaim &&
          device?.status === constants.DISCOVERED_DEVICES_ENTER) ||
          (!isToggleClaim &&
            device?.status === constants.DISCOVERED_DEVICES_RECLAIM))
      ) {
        list.push(device);
      }
    });
    setSelectEligibleDevices(list);
  }, [selectedDevices, filteredDeviceData, isToggleClaim]);

  const fetchDiscoveredDevices = async (search) => {
    setShowLoader(true);
    setSearchText(search?.trim());
    let announcedDevicesAPIData = await announcedDevicesAPI(
      orgId,
      pageDetails,
      search?.trim(),
      filtersData,
      filterDate,
      deviceTypes,
    );
    if (announcedDevicesAPIData.devices?.length) {
      fetchDevicesBySNoMac(announcedDevicesAPIData.devices, search?.trim());
    } else {
      setFilteredDeviceData(announcedDevicesAPIData.devices);
      setShowLoader(false);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(search?.trim());
    }
    setTotalDevices(announcedDevicesAPIData?.totalElements);
    setTotalPages(announcedDevicesAPIData?.totalPages);
  };

  const fetchDevicesBySNoMac = async (devices, search) => {
    const requestData = [];
    devices.forEach((device) => {
      const obj = {};
      obj.serialNo = device?.serialNo;
      obj.macAddress = device?.macAddress;
      requestData.push(obj);
    });
    if (requestData?.length) {
      let sNoMacAPIData = await fetchDevicesBysNOMacAPI(orgId, requestData);
      devices?.forEach((device) => {
        device = addDeviceProperties(
          device,
          0,
          sNoMacAPIData?.devices ? sNoMacAPIData?.devices : [],
        );
      });
      setFilteredDeviceData(devices);
      setShowLoader(false);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(search);
    } else {
      setFilteredDeviceData(devices);
      setShowLoader(false);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(search);
    }
  };

  const addDeviceProperties = (device, stepCount, sNoMacList) => {
    const deviceCopies = sNoMacList?.filter(
      (d) =>
        d.serialNo === device.serialNo && d.macAddress === device.macAddress,
    );
    const deviceCopy = deviceCopies?.find(
      (d) =>
        d.deviceStatus?.toLowerCase() === 'claimed' &&
        (d.deviceType?.toLowerCase() === 'nwc' ||
          d.deviceType?.toLowerCase() === 'onvifcam'),
    );
    if (deviceCopy && deviceCopies?.length === 1) {
      device.status = constants.DISCOVERED_DEVICES_ENTER;
    } else if (deviceCopies?.length > 0) {
      device.status = constants.DISCOVERED_DEVICES_RECLAIM;
    } else {
      device.status = constants.DISCOVERED_DEVICES_ENTER;
    }
    if (device.locationId) {
      const location = locationsData.find(
        (loc) => loc.locationId === device.locationId,
      );
      if (location) {
        device.timezone = location.timezone;
        device.areas = location?.areas;
        if (deviceCopy) {
          device.locationName = location?.locationName;
          if (device?.areaId) {
            const areaCopy = location?.areas?.find(
              (area) => area.areaId === device?.areaId,
            );
            device.areaName = areaCopy?.areaName;
          }
        }
      }
    }
    device.requestedApps = [];
    device['stepCount'] = stepCount;
    return device;
  };

  const updateLocationAndArea = (location, area, reclaimOption) => {
    const regObject = [];
    selectedDevices.forEach((deviceId) => {
      const device = filteredDeviceData.find((d) => d.deviceId === deviceId);
      if (location.locationId) {
        device.locationId = location.locationId;
        device.locationName = location.locationName;
        device.timezone = location.timezone;
        device.areas = location?.areas;
        if (area.areaId) {
          device.areaId = area.areaId;
          device.areaName = area.areaName;
        } else {
          device.areaId = null;
          device.areaName = null;
        }
      }
      regObject.push(device);
    });
    if (isToggleClaim || reclaimOption === constants.DISCOVERED_DEVICES_ADD) {
      addDiscoveredDevices(regObject);
    } else {
      reclaimDiscoveredDevices(regObject);
    }
  };

  const onAddAllDevices = async (location, area, reclaimOption) => {
    let updateAPIData = await addAllAnnouncedDevicesAPI(
      orgId,
      isToggleClaim,
      reclaimOption === constants.DISCOVERED_DEVICES_RECLAIM,
      location,
      area,
    );
    if (!updateAPIData?.userMsg) {
      onUnselectAllDevices();
      navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
    } else {
      setShowLoader(false);
    }
  };

  const onRegisterDevices = (location, area, reclaimOption) => {
    setIsDisplayLocationAreaModal(false);
    setShowLoader(true);
    if (!isAddAllSelected) {
      updateLocationAndArea(location, area, reclaimOption);
    } else {
      onAddAllDevices(location, area, reclaimOption);
    }
  };

  const addDiscoveredDevices = async (addRequestedDevices) => {
    let updateAPIData = await addAnnouncedDevicesAPI(
      orgId,
      addRequestedDevices,
    );
    if (!updateAPIData?.userMsg) {
      onUnselectAllDevices();
      navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
    } else {
      setShowLoader(false);
    }
  };

  const reclaimDiscoveredDevices = async (reclaimRequestedDevices) => {
    let updateAPIData = await reclaimAnnouncedDevicesAPI(
      orgId,
      reclaimRequestedDevices,
    );
    if (!updateAPIData?.userMsg) {
      onUnselectAllDevices();
      navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
    } else {
      setShowLoader(false);
    }
  };

  const onSelectAddOptionHandler = (evtKey) => {
    if (Number(evtKey)) {
      setIsAddAllSelected(true);
    }
    setIsDisplayLocationAreaModal(true);
  };

  const checkAnyD2CInSelectedDevices = (selectedDevices) => {
    let status = false;
    selectedDevices.forEach((selectedDeviceId) => {
      const device = filteredDeviceData.find(
        (d) => d?.deviceId === selectedDeviceId,
      );
      if (device) {
        status =
          status ||
          ((device?.deviceType?.toUpperCase() === DeviceTypeEnum.IPCam ||
            device.deviceType.toLowerCase() === 'nwc' ||
            device.deviceType.toLowerCase() === 'onvifcam') &&
            device?.stepCount) ||
          device.deviceType === DeviceTypeEnum.WAVE;
      }
    });
    setIsD2CInSelectedDevices(status);
  };

  const onSelectAllDevices = () => {
    const deviceList = [];
    filteredDeviceData.forEach((device) => {
      if (
        device.connectionStatus !==
          constants.DEVICES_OFFLINE_CONNECTION_STATUS &&
        ((isToggleClaim &&
          device?.status === constants.DISCOVERED_DEVICES_ENTER) ||
          (!isToggleClaim &&
            device?.status === constants.DISCOVERED_DEVICES_RECLAIM))
      ) {
        deviceList.push(device.deviceId);
      }
    });
    setSelectedDevices(deviceList);
    checkAnyD2CInSelectedDevices(deviceList);
  };

  const onUnselectAllDevices = () => {
    setSelectedDevices([]);
    setIsD2CInSelectedDevices(false);
  };

  const onChanegCheckBox = (e, device) => {
    const { checked } = e.target;
    let checkList = structuredClone(selectedDevices);
    if (checked) {
      checkList.push(device?.deviceId);
    } else {
      const index = checkList.indexOf(device?.deviceId, 0);
      if (index > -1) {
        checkList.splice(index, 1);
      }
    }
    const newList = [...new Set(checkList)];
    setSelectedDevices(newList);
    checkAnyD2CInSelectedDevices(newList);
  };

  const onRemoveDevice = async () => {
    setIsDisplayRemoveDeviceModal(false);
    setShowLoader(true);
    let updateAPIResponse = await removeAnnouncedDevicesAPI(
      orgId,
      selectedDevices,
    );

    if (updateAPIResponse?.code === 200 || updateAPIResponse?.code === 2303) {
      if (pageDetails?.page) {
        setPageDetails(defaultPageDetails);
      } else {
        fetchDiscoveredDevices(searchText);
      }
      onUnselectAllDevices();
    }
  };

  const fetchDeviceTypes = async () => {
    const deviceTypesData = await getDeviceTypesAPI(orgId);
    if (deviceTypesData?.deviceTypes) {
      const deviceTypes = deviceTypesData?.deviceTypes.map(
        (deviceType) => deviceType?.id,
      );
      setDeviceTypes(deviceTypes);
    }
  };

  const handleDateChange = (data) => {
    setIsDisplayDateRangeModal(false);
    setFilterDate(data);
  };

  const renameRecursive = (device, newName) => {
    if (device?.deviceId === renamingDevice?.deviceId) {
      device.deviceName = newName;
      return;
    }
    if (device?.childDevices?.length) {
      device?.childDevices?.forEach((childDevice) => {
        renameRecursive(childDevice, newName);
      });
    }
  };

  const onRenameDevice = async (newName) => {
    setIsRenamingDevice(true);
    const requestObj = {
      deviceId: renamingDevice.deviceId,
      deviceName: newName,
    };
    let updateAPIData = await updateAnnouncedDevicesAPI(orgId, [requestObj]);

    setIsRenamingDevice(false);
    if (updateAPIData?.length) {
      const rawFilteredDeviceData = structuredClone(filteredDeviceData);
      rawFilteredDeviceData.forEach((device) => {
        renameRecursive(device, newName);
      });
      setFilteredDeviceData(rawFilteredDeviceData);
      setRenamingDevice(null);
      setRenamedDeviceName(null);
      setIsCheckForSearchCSS(!isCheckForSearchCSS);
      setSearchedText(searchText);
    }
  };

  const collapseRecursive = (device, deviceCollapsed) => {
    if (device?.deviceId === deviceCollapsed?.deviceId) {
      device.isExpanded = !device.isExpanded;
      device.childDevices = [];
      return;
    }
    if (device?.childDevices?.length) {
      device?.childDevices?.forEach((childDevice) => {
        collapseRecursive(childDevice, deviceCollapsed);
      });
    }
  };
  const onCollapseExpandDevice = (deviceCollapsed) => {
    const rawFilteredDeviceData = structuredClone(filteredDeviceData);
    rawFilteredDeviceData.forEach((device) => {
      collapseRecursive(device, deviceCollapsed);
    });
    setFilteredDeviceData(rawFilteredDeviceData);
    if (!deviceCollapsed?.isExpanded) {
      deviceCollapsed.childPageDetails = childNewPageDetails;
      setExpandedParent(deviceCollapsed);
    }
  };

  const onSort = (heading) => {
    const rawPageDetails = structuredClone(pageDetails);

    if (rawPageDetails.orderBy === heading?.id) {
      rawPageDetails.isAsc = !pageDetails.isAsc;
    } else {
      rawPageDetails.isAsc = sassTrue;
    }
    rawPageDetails.orderBy = heading?.id;
    rawPageDetails.page = 0;
    setPageDetails(rawPageDetails);
    setSelectedDevices([]);
  };

  const onchangePageNo = (pageNo) => {
    const rawPageDetails = structuredClone(pageDetails);
    rawPageDetails.page = pageNo;
    if (JSON.stringify(rawPageDetails) === JSON.stringify(pageDetails)) {
      fetchDiscoveredDevices(searchText);
    } else {
      setPageDetails(rawPageDetails);
    }
  };

  const onClickReset = () => {
    setFilterDate(defaultDate);
    onUnselectAllDevices();
    setSearchText('');
    setSearchedText('');
    if (
      JSON.stringify(pageDetails) === JSON.stringify(defaultPageDetails) &&
      JSON.stringify(filtersData) === JSON.stringify(defaultFilters)
    ) {
      fetchDiscoveredDevices('');
    } else {
      setPageDetails(defaultPageDetails);
      setFiltersData(defaultFilters);
    }
  };

  const displayDataWithTooltip = (value, search) => {
    let chunks = [];
    if (search && value) {
      chunks = highlightWords({
        text: value,
        query: searchedText,
        matchExactly: true,
      });
    }
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip>{value}</Tooltip>}>
        <span className={search ? 'searchStyles' : ''}>
          {searchedText && search
            ? chunks.map(({ text, match, key }) =>
                match ? (
                  <span className="searched-text" key={key}>
                    {text}
                  </span>
                ) : (
                  <span key={key}>{text}</span>
                ),
              )
            : value}
        </span>
      </OverlayTrigger>
    );
  };

  const displayRowCheckBox = (device) => {
    return (
      <span className="delete-checkbox">
        <input
          type="checkbox"
          checked={selectedDevices?.includes(device?.deviceId)}
          disabled={
            device.connectionStatus ===
            constants.DEVICES_OFFLINE_CONNECTION_STATUS
          }
          className={`delete-checkbox
                  ${
                    device.connectionStatus ===
                    constants.DEVICES_OFFLINE_CONNECTION_STATUS
                      ? 'disabled'
                      : ''
                  }
                `}
          onChange={(e) => onChanegCheckBox(e, device)}
          role="button"
        />
      </span>
    );
  };

  const displayAddedDateColumnDetails = (device) => {
    return moment(device?.createdDate).format('MMM DD, YYYY');
  };

  const displayDeviceStatusColumnDetails = (device) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {Utils.getDeviceStatusWithDot(device?.connectionStatus)}
          </Tooltip>
        }
      >
        {Utils.getDeviceStatusWithDot(device?.connectionStatus)}
      </OverlayTrigger>
    );
  };

  const displayRowAccordian = (device) => {
    return (
      <>
        {expandedParent?.deviceId === device?.deviceId &&
        !device?.childDevices?.length ? (
          <span className={``}>
            <SiteSpinner
              width="16px"
              height="16px"
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_black')}
            />
          </span>
        ) : device.isExpanded ? (
          <HiOutlineChevronDown
            size={20}
            color="#747E87"
            className={` ${device?.count > 2 ? '' : 'hide'}`}
            onClick={() => {
              onCollapseExpandDevice(device);
            }}
            role="button"
          />
        ) : (
          <HiOutlineChevronRight
            size={20}
            color="#747E87"
            className={`${device?.count > 2 ? '' : 'hide'}`}
            onClick={() => {
              onCollapseExpandDevice(device);
            }}
            role="button"
          />
        )}
      </>
    );
  };

  const displayDeviceNameColumnDetails = (device) => {
    const chunks = highlightWords({
      text: device?.deviceName,
      query: searchedText,
      matchExactly: true,
    });
    return (
      <>
        {device?.stepCount ? (
          <StepBottomIcon
            size={20}
            color="#0000004A"
            className={`margin-rt`}
            style={{
              marginLeft:
                device?.stepCount > 1 ? `${device?.stepCount * 32}px` : '',
            }}
          />
        ) : null}
        {Utils.getDeviceIcon(device)}
        {renamingDevice?.deviceId === device?.deviceId ? (
          <span className="edit-name-container">
            <input
              autoFocus={true}
              maxLength={40}
              type={'text'}
              placeholder={'Name'}
              className={`rename-input ${isRenamingDevice ? 'renaming' : ''}`}
              defaultValue={
                device.deviceName ? device.deviceName : device.serialNo
              }
              onChange={(e) => setRenamedDeviceName(e?.target?.value)}
              ref={renameInputRef}
              onKeyDown={(e) => {
                if (
                  e.key === constants.DISCOVERED_DEVICES_ENTER &&
                  renamedDeviceName
                ) {
                  onRenameDevice(e.target.value);
                }
              }}
            />

            {isRenamingDevice ? (
              <SiteSpinner
                width="16px"
                height="16px"
                backgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--brand_black')}
              />
            ) : (
              <IoCloseCircle
                className="cross-icon"
                size={16}
                onClick={() => {
                  setRenamingDevice(null);
                  setRenamedDeviceName(null);
                }}
                color="#747E87"
                role="button"
              />
            )}
          </span>
        ) : (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{device.deviceName}</Tooltip>}
          >
            <span
              className={'searchStyles'}
              onClick={(e) => {
                if (renamingDevice?.deviceId !== device?.deviceId) {
                  e.stopPropagation();
                  e.preventDefault();
                  setRenamingDevice(device);
                  setRenamedDeviceName(device?.deviceName);
                }
              }}
            >
              {chunks.map(({ text, match, key }) =>
                match ? (
                  <span className="searched-text" key={key}>
                    {text}
                  </span>
                ) : (
                  <span key={key}>{text}</span>
                ),
              )}
            </span>
          </OverlayTrigger>
        )}
      </>
    );
  };

  const displayDeviceRowDetails = (device) => {
    return (
      <tr
        className={`row-bg ${device?.isExpanded ? 'expanded-parent-row' : ''}
        ${
          (isToggleClaim &&
            device?.status !== constants.DISCOVERED_DEVICES_ENTER) ||
          (!isToggleClaim &&
            device?.status === constants.DISCOVERED_DEVICES_ENTER)
            ? 'disabled-row'
            : ''
        }`}
      >
        <td>{displayRowAccordian(device)}</td>
        <td>{displayRowCheckBox(device)}</td>
        <td
          className={`
            ${
              device?.count > 2 || !device?.stepCount
                ? 'parent-name'
                : 'device-name-color'
            }
          `}
        >
          {displayDeviceNameColumnDetails(device)}
        </td>
        <td>{displayDataWithTooltip(device.modelNumber, true)}</td>

        <td>{displayDataWithTooltip(device.serialNo, true)}</td>
        <td>{displayDataWithTooltip(device.ipAddress, true)}</td>

        <td>{displayDeviceStatusColumnDetails(device)}</td>
        <td>{displayAddedDateColumnDetails(device)}</td>
      </tr>
    );
  };

  const displayChildDetails = (device) => {
    return (
      <>
        {device?.isExpanded && device?.childDevices?.length
          ? device?.childDevices?.map((childDevice) => (
              <>
                {displayDeviceRowDetails(childDevice)}
                <>
                  {childDevice?.childDevices?.length &&
                    childDevice?.childDevices?.map((grandChildDevice) => (
                      <>{displayChildDetails(grandChildDevice)}</>
                    ))}
                </>
              </>
            ))
          : null}

        {device?.isExpanded &&
        device?.childPageDetails?.page + 1 <
          device?.childPageDetails?.totalPages &&
        device?.childDevices?.length ? (
          <tr>
            <div
              className={`load-more ${
                device?.childPageDetails?.page + 1 >=
                device?.childPageDetails?.totalPages
                  ? 'load-more-disabled'
                  : ''
              } load-more-child`}
              role="button"
              onClick={() => {
                if (
                  device?.childPageDetails?.page + 1 <
                  device?.childPageDetails?.totalPages
                ) {
                  setExpandedParent(device);
                }
              }}
            >
              {expandedParent?.deviceId === device?.deviceId &&
              device?.childDevices?.length ? (
                <span className="margin-rt-4 d-flex">
                  <SiteSpinner
                    width="16px"
                    height="16px"
                    backgroundColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--brand_black')}
                  />
                </span>
              ) : (
                <BsPlus
                  size={20}
                  disabled={
                    device?.childPageDetails?.page + 1 >=
                    device?.childPageDetails?.totalPages
                  }
                />
              )}
              {constants.DEVICES_HEADER_BUTTON_LOAD_MORE}
            </div>
          </tr>
        ) : null}
      </>
    );
  };

  const displayGatewayDetails = () => {
    return (
      <>
        {!filteredDeviceData?.length && showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="loader-container">
              <SiteSpinner width="60px" height="60px" />
            </td>
          </tr>
        ) : filteredDeviceData?.length ? (
          <>
            {filteredDeviceData?.map((device, index) => (
              <>
                {index < (pageDetails?.page + 1) * 100
                  ? displayDeviceRowDetails(device)
                  : null}
                {displayChildDetails(device)}
              </>
            ))}
          </>
        ) : !showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="no-device-container">
              <img
                alt="exclamation"
                src={CircleExclamation}
                width={'60px'}
                height={'60px'}
              />
              <div className="no-device-content mt-3">
                <div className="no-device-heading">
                  {constants.SCAN_NETWORK_NO_DEVICE_TITLE}
                </div>
                <div className="no-device-text">
                  {constants.DEVICES_TABLE_NO_DEVICES_TEXT}
                </div>
              </div>
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  const getHeaderStyle = (id) => {
    if (id === 'arrow' || id === 'checkbox') {
      return 'fixed-column';
    }
    return null;
  };

  const displayHeaderDetails = (headerList) => {
    return (
      <>
        <tr>
          {headerList?.map((heading) => (
            <th className={getHeaderStyle(heading?.id)}>
              <div className={heading.id}>
                {heading?.displayName}
                {heading?.id === 'arrow' ||
                heading?.id === 'applications-linked' ||
                heading?.id === 'location' ||
                heading?.id === 'area' ? null : heading?.id === 'checkbox' ? (
                  <div className="delete-checkbox">
                    <input
                      type="checkbox"
                      className="delete-checkbox "
                      onChange={(e) => {
                        const { checked } = e.target;
                        if (checked) {
                          onSelectAllDevices();
                        } else {
                          onUnselectAllDevices();
                        }
                      }}
                      checked={
                        selectedDevices?.length &&
                        selectedDevices?.length ===
                          selectEligibleDevices?.length
                      }
                      role="button"
                    />
                  </div>
                ) : pageDetails.orderBy === heading?.id && pageDetails.isAsc ? (
                  <IoIosArrowRoundDown
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                ) : pageDetails.orderBy === heading?.id &&
                  !pageDetails.isAsc ? (
                  <IoIosArrowRoundUp
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                ) : (
                  <RxCaretSort
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </>
    );
  };

  const addOptionsDropdownContent = addOptions?.map((option, index) => (
    <Dropdown.Item
      key={`add-${option}`}
      className={`add-device-selector-menu-item`}
      eventKey={index}
      disabled={!selectedDevices?.length && !index}
    >
      {option}
    </Dropdown.Item>
  ));

  const LoaderOverlay = () => {
    return <div className="position-fixed l-48 loader-style"></div>;
  };

  return (
    <div className="App">
      <Header breadcrumbData={breadList} className="Desktop" />
      <LoadingOverlay active={showLoader} spinner={LoaderOverlay()}>
        <div
          className="paginated-device-page"
          onClick={() => {
            isDisplayDateRangeModal && setIsDisplayDateRangeModal(false);
          }}
        >
          <div className="paginated-devices">
            <div className="devices-top-area">
              <div className="devices-header">
                <div className="devices-title">
                  <div
                    role="button"
                    onClick={() => {
                      navigate(`/devices/listing.html?orgId=${orgInfo?.orgId}`);
                    }}
                  >
                    <HiOutlineChevronLeft
                      size={24}
                      color="#000000"
                      className="margin-rt"
                      role="button"
                    />
                    {constants.DISCOVERED_DEVICES_SETUP_BUTTON}
                  </div>
                </div>
                <BreadcrumbList
                  list={Utils.CheckBreadcrumbForUserRole(breadList)}
                />
              </div>
              <div className="toggle-container">
                <div
                  role="button"
                  onClick={() => {
                    setIsToggleClaim(!isToggleClaim);
                    onUnselectAllDevices();
                  }}
                  className={
                    isToggleClaim ? 'claim-selected' : 'claim-unselected'
                  }
                >
                  {constants.DISCOVERED_DEVICES_CLAIM}
                </div>
                <div
                  role="button"
                  onClick={() => {
                    setIsToggleClaim(!isToggleClaim);
                    onUnselectAllDevices();
                  }}
                  className={
                    !isToggleClaim ? 'reclaim-selected' : 'reclaim-unselected'
                  }
                >
                  {constants.DISCOVERED_DEVICES_RECLAIM}
                </div>
              </div>
              <div className="devices-filters">
                <div className="left-side">
                  <Dropdown
                    className="add-device-selector"
                    onSelect={onSelectAddOptionHandler}
                  >
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="dropdown-basic"
                      className="add-device-selector-toggle"
                      split
                    >
                      <div className="add-device-selector-wrapper">
                        <span className="add-device-selector-name">
                          {constants.DEVICES_MODAL_Add}
                        </span>
                      </div>
                      <HiOutlineChevronDown size={20} color="#747E87" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="add-device-selector-menu">
                      {addOptionsDropdownContent}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div
                    className={`reload-filter-edit remove-device ${
                      !selectedDevices?.length ? 'disabled' : ''
                    }`}
                    role="button"
                    onClick={() => {
                      setIsDisplayRemoveDeviceModal(true);
                    }}
                  >
                    {constants.DEVICES_MODAL_REMOVE}
                  </div>
                  {/* <div className="count-container">
                  <div className="display-bold">
                    {filteredDeviceData?.length}
                  </div>
                  <div>of {totalDevices}</div>
                </div> */}
                  <div className="count-container">
                    ({' '}
                    <div className="paginated-claiming-device">
                      {selectedDevices?.length}{' '}
                    </div>
                    <div> {constants.DEVICES_HEADER_SELECTED}</div>
                  </div>
                </div>
                <div className="right-side">
                  <div className="search-container">
                    <IoIosSearch
                      className="search-icon"
                      size={24}
                      role="button"
                      onClick={() => {
                        onUnselectAllDevices();
                        if (pageDetails?.page) {
                          setPageDetails(defaultPageDetails);
                        } else {
                          fetchDiscoveredDevices(searchText?.trim());
                        }
                      }}
                    />
                    <input
                      type={'text'}
                      placeholder={'Search'}
                      className="search-input"
                      value={searchText}
                      onChange={(e) => setSearchText(e?.target?.value)}
                      onKeyDown={(e) => {
                        if (e.key === constants.DISCOVERED_DEVICES_ENTER) {
                          onUnselectAllDevices();
                          if (pageDetails?.page) {
                            setPageDetails(defaultPageDetails);
                          } else {
                            fetchDiscoveredDevices(e?.target?.value);
                          }
                        }
                      }}
                    />
                    {searchText ? (
                      <IoCloseCircle
                        className="search-close"
                        size={16}
                        onClick={() => {
                          setSearchText('');
                          setSearchedText('');
                          fetchDiscoveredDevices('');
                        }}
                        color="#747E87"
                        role="button"
                      />
                    ) : null}
                  </div>
                  <div
                    className="reload-filter-edit"
                    role="button"
                    onClick={() => setIsDisplayDateRangeModal(true)}
                  >
                    {filterDate?.startDate} - {filterDate?.endDate}
                  </div>
                  <div
                    className="reload-filter-edit"
                    role="button"
                    onClick={() => setIsDisplayFiltersModal(true)}
                  >
                    <CiFilter size={20} />
                    {constants.DEVICES_HEADER_BUTTON_FILTER}
                  </div>
                  <div
                    className="reload-filter-edit"
                    role="button"
                    onClick={() => onClickReset()}
                  >
                    <TfiReload size={16} />
                    {constants.DEVICES_HEADER_BUTTON_RELOAD}
                  </div>
                </div>
              </div>
            </div>
            {filteredDeviceData?.length && showLoader ? (
              <div className="position-absolute screen-loader-container l-48 t-52">
                <SiteSpinner width="60px" height="60px" />
              </div>
            ) : null}
            <div className="devices-bottom-area">
              <div className="devices-bottom-area-contents">
                <ResizableTable
                  widths={[29, 26, 420, 240, 340, 190, 140, 180]}
                  minWidths={[29, 26, 285, 200, 270, 160, 100, 140]}
                  minWidth={29}
                  disabledColumns={[0, 1]}
                  displayHeaderDetails={() =>
                    displayHeaderDetails(paginatedHeaderList)
                  }
                  displayGatewayDetails={() => displayGatewayDetails()}
                  totalPages={totalPages}
                  totalElements={totalDevices}
                  pageDetails={pageDetails}
                  showPagination={true}
                  onchangePageNo={onchangePageNo}
                />
              </div>
            </div>
          </div>

          <SiteModal
            modalTitle=""
            modalHeader={true}
            showModal={isDisplayFiltersModal}
            hideModal={() => {
              setIsDisplayFiltersModal(false);
            }}
            classes="discovered-device-filter-modal"
            key="discovered-device-filter-modal"
            wrapperClass="discovered-device-filter-modal"
          >
            <DevicesFilters
              closeModal={() => setIsDisplayFiltersModal(false)}
              fromDiscoveredDevices={true}
              apiConnectTypes={deviceTypes}
              setFiltersData={(e) => {
                setPageDetails(defaultPageDetails);
                setFiltersData(e);
                onUnselectAllDevices();
              }}
              filtersData={filtersData}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.DISCOVERED_DEVICES_ADD}
            showModal={isDisplayLocationAreaModal}
            hideModal={() => {
              setIsDisplayLocationAreaModal(false);
              setIsAddAllSelected(false);
            }}
            classes="location-area-selector-modal"
            key="location-area-selector-modal"
            wrapperClass="location-area-selector-modal"
          >
            <DiscoveredDevicesRegister
              closeModal={() => {
                setIsDisplayLocationAreaModal(false);
                setIsAddAllSelected(false);
              }}
              onRegisterDevices={onRegisterDevices}
              isD2CInSelectedDevices={isD2CInSelectedDevices}
              isToggleClaim={isToggleClaim}
              selectedDevices={selectedDevices}
              isAddAllSelected={isAddAllSelected}
            />
          </SiteModal>
          <SiteModal
            modalTitle={constants.DEVICES_MODAL_REMOVE_DEVICE}
            showModal={isDisplayRemoveDeviceModal}
            hideModal={() => {
              setIsDisplayRemoveDeviceModal(false);
            }}
            classes="location-area-selector-modal"
            key="remove-device-modal"
            wrapperClass="location-area-selector-modal"
          >
            <RemoveDeviceModal
              closeModal={() => setIsDisplayRemoveDeviceModal(false)}
              fromDiscoveredDevices={true}
              onRemoveDevice={onRemoveDevice}
            />
          </SiteModal>
          <SiteModal
            modalTitle=""
            modalHeader={true}
            showModal={isDisplayDateRangeModal}
            hideModal={() => {
              setIsDisplayDateRangeModal(false);
            }}
            classes="discovered-device-date-modal"
            size={'sm'}
            onClick={(e) => e.stopPropagation()}
          >
            <DateTimePicker
              filterDate={filterDate}
              onSubmit={handleDateChange}
            />
          </SiteModal>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default DiscoveredDevices;
