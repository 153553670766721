import { useMemo, useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import {
  Avatar,
  BreadcrumbList,
  Header,
  SiteSpinner,
  SiteModal,
  PrimaryButton,
  TextBlock,
} from '../../components/common';
import { CheckField } from '../../components/forms';
import PageWrapper from '../PageWrapper';
import { constants, Utils, roles } from '../../helpers';
import { TechniciansListTable } from '.';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { BiCheckCircle } from 'react-icons/bi';
import { BsXCircleFill } from 'react-icons/bs';
import './Technicians.scss';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { getPartnerOrgData } from '../../store/reducers/OrganizationsReducer';
import { getSelectedOrganization } from '../../store/AccountStoreIDB';

const ManageTechnicians = () => {
  const [tokenData, setTokenData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [savedStatus, setSavedStatus] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [userdesc, setUserDesc] = useState('');
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const navigate = useNavigate();
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const loggedInUser = getLoggedInUserData();
  //=== Store get/set actions
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData,
  );
  const setCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.setCustomerOrgUsers,
  );
  // const getPartnerOrgData = useOrganizations(
  //   (state) => state.getPartnerOrgData
  // );
  const partnerOrgData = useSelector(getPartnerOrgData);
  const setPatnerOrgUser = useCustomerOrgUsers(
    (state) => state.setPatnerOrgUser,
  );
  const getPartnerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getPartnerOrgUsersData,
  );
  const [partnerTechnician, setPartnerTechnician] = useState(
    getPartnerOrgUsersData(),
  );
  const [orgData, setOrgData] = useState();
  // const customerOrgData = useOrganizations((state) => state.customerOrgData);
  // const orgData = customerOrgData?.find((org) => org.orgId === orgId);
  // const orgNameBread = orgData?.orgName;
  const [orgNameBread, setOrgNameBread] = useState('');

  const breadList = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2PORTAL &&
        loggedInUser.role !== roles.ROLE7PORTAL
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: orgData?.role
        ? `/landing-page.html?orgId=${orgId}&orgName=${orgNameBread}`
        : `/customers/dashboard.html?orgId=${orgId}`,
      title: `${orgNameBread}`,
    },
    {
      url: `/managetechnicians.html?orgId=${orgId}`,
      title: constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE,
    },
  ];
  const { keycloak, initialized } = useKeycloak();
  const token = keycloak.token;

  useEffect(() => {
    const setOrg = async () => {
      const org = await getSelectedOrganization();
      setOrgData(org || {});
      setOrgNameBread(org?.orgName);
    };
    setOrg();
  }, []);

  //=== Set token and load the partner account requests list
  useEffect(() => {
    if (token && !tokenData) {
      localStorage.setItem('vmsAuthToken', token);
      setTokenData(token);
    }
  }, [token, tokenData, orgId]);

  useEffect(() => {
    if (userMsg !== '') {
      setShowErrorModal(true);
    }
  }, [userMsg]);

  const fetchUserTech = async () => {
    try {
      await axios
        .get(`partner/orgs/${partnerOrgData?.orgId}/accounts`, {
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (
            response?.data?.meta?.code === 200 ||
            response?.data?.meta?.code === '200'
          ) {
            setPatnerOrgUser(response?.data?.data);
            setPartnerTechnician(response?.data?.data);
          }
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  //=== Hanle assign technicians on click of checkboxes
  const handleAssignTechnitians = (curVal, accountId, rowClicked) => {
    setShowLoader(true);
    let usersData = getPartnerOrgUsersData();
    if (usersData) {
      let accountIds = [];
      let userSelected = [];
      usersData?.forEach((element) => {
        if (curVal) {
          let account = getCustomerOrgUsersData()?.find(
            (user) => user.accountId === element?.accountId,
          );
          if (account) {
            if (!accountIds.includes(account?.accountId)) {
              accountIds.push(account?.accountId);
            }
          }
          if (curVal && element?.accountId === accountId) {
            if (!accountIds.includes(element?.accountId)) {
              accountIds.push(element?.accountId);
              userSelected[0] = element;
            }
          } else if (
            curVal &&
            element?.accountId === accountId &&
            element?.role === roles.ROLE1PORTAL
          ) {
            if (!accountIds.includes(element?.accountId)) {
              accountIds.push(element?.accountId);
            }
            userSelected[0] = element;
          }
        } else {
          if (
            element?.accountId !== accountId &&
            element?.role === roles.ROLE1PORTAL
          ) {
            if (!accountIds.includes(element?.accountId)) {
              accountIds.push(element?.accountId);
            }
          }
          let account = getCustomerOrgUsersData()?.find(
            (user) => user.accountId === element?.accountId,
          );
          if (account) {
            if (!accountIds.includes(account?.accountId)) {
              accountIds.push(account?.accountId);
            }
          }
          accountIds = accountIds?.filter(function (item) {
            return item !== accountId;
          });
        }
      });

      if (accountIds.length > 0 || accountIds.length == 0) {
        axios
          .put(
            `partner/orgs/${partnerOrgData?.orgId}/assignTechnicians`,
            {
              orgId: orgId,
              accountIds: accountIds,
            },
            Utils.requestHeader(),
          )
          .then((res) => {
            let response = res.data;
            if (response.meta.code === 200) {
              if (curVal)
                setCustomerOrgUsers([
                  ...getCustomerOrgUsersData(),
                  ...userSelected,
                ]);
              else {
                const account = getCustomerOrgUsersData()?.filter(
                  function (item) {
                    return item.accountId !== accountId;
                  },
                );
                account && setCustomerOrgUsers(account);
                if (rowClicked?.email === partnerOrgData?.contactEmail) {
                  navigate('/customers/manage.html');
                }
              }
              fetchUserTech();
              setSavedStatus(true);
            } else {
              setUserDesc(response.meta.desc);
              setUserMsg(response.meta.userMsg);
            }
            setShowLoader(false);
          })
          .catch(function (error) {
            setShowErrorModal(true);
            setShowLoader(false);
          });
      }
    } else setShowLoader(false);
  };

  const columnsData = useMemo(
    () => [
      {
        accessor: 'accountId',
        Cell: (props) => {
          let cellVal = props?.value;
          let allRecords = props?.data;
          let ifDataExists = allRecords?.find(
            (row) => row.accountId === cellVal,
          );
          let account = getCustomerOrgUsersData()?.find(
            (user) => user.accountId === cellVal,
          );

          return (
            <CheckField
              type="checkbox"
              className="form-check-input"
              checked={account?.accountId === ifDataExists?.accountId}
              value={props.value}
              onChange={(event) => {
                let curVal = event.target.checked;
                handleAssignTechnitians(curVal, cellVal, ifDataExists);
              }}
            />
          );
        },
        sortType: 'basic',
      },
      {
        Header: constants.NAME_TABLE_HEADER,
        accessor: 'name',

        Cell: ({ row }) => {
          let technician = row.original;
          let firstName = technician?.firstName?.charAt(0)?.toUpperCase();
          let lastName = technician?.lastName?.charAt(0)?.toUpperCase();
          let letter = firstName + lastName;
          return (
            <span className="userName">
              <Avatar
                valueType={technician?.image?.url ? 'icon' : 'text'}
                value={technician?.image?.url ? technician?.image?.url : letter}
                size="medium"
                avatarStyle="roundedCircle"
              />
              {technician?.name}
            </span>
          );
        },
        sortType: 'basic',
      },
      {
        Header: constants.EMAIL_TABLE_HEADER,
        accessor: 'email',

        sortType: 'basic',
      },
      {
        Header: constants.PHONE_TABLE_HEADER,
        accessor: 'phoneNumber',
        sortType: 'basic',
      },
      {
        Header: '',
        accessor: '...',
        sortType: 'basic',
      },
    ],
    [],
  );

  return (
    <div className="App assign-technicians">
      <Header breadcrumbData={breadList} />

      <PageWrapper className="mw-100">
        <div className="page-header">
          <Container className="mw-100">
            <Row>
              <Col className="text-start">
                <BreadcrumbList list={breadList} />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                <div className="d-flex">
                  {constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE}
                  {showLoader && (
                    <div className="d-flex align-items-center saving-spinner">
                      <SiteSpinner height="20px" width="20px"></SiteSpinner>
                      <TextBlock
                        fontWeight="600"
                        fontSize="0.875rem"
                        lineHeight="20px"
                        color={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--primary_40')}
                      >
                        {constants.ASSIGN_TECHNICIANS_LOADER_TEXT}
                      </TextBlock>
                    </div>
                  )}
                  {savedStatus && (
                    <div className="d-flex align-items-center saved-tech">
                      <BiCheckCircle
                        size={15}
                        strokeWidth="1.5"
                        className="saved-tech-icon"
                      />
                      <TextBlock
                        fontWeight="600"
                        fontSize="0.875rem"
                        lineHeight="20px"
                        color={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--success_48')}
                      >
                        {constants.ASSIGN_TECHNICIANS_SAVED_TEXT}
                      </TextBlock>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="list-block">
          <Container className="h-100 mw-100">
            <TechniciansListTable
              columns={columnsData}
              data={partnerTechnician}
            />

            {/* Show error messages */}
            <SiteModal
              modalTitle={userdesc}
              showModal={showErrorModal}
              hideModal={() => setShowErrorModal(false)}
              classes="technicians-error-modal"
            >
              <div className="text-center">
                <TextBlock
                  fontWeight="400"
                  fontSize="1rem"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--brand_primary')}
                >
                  {userMsg}
                </TextBlock>

                <BsXCircleFill
                  className="mt-4 mb-4 icon-error-color"
                  size={96}
                />

                <PrimaryButton
                  className="btn btn-primary w-100"
                  type="button"
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  backgroundColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  onClick={() => {
                    setShowErrorModal(false);
                  }}
                >
                  {constants.CLOSE_BUTTON}
                </PrimaryButton>
              </div>
            </SiteModal>
          </Container>
        </div>
      </PageWrapper>
    </div>
  );
};

export default ManageTechnicians;
