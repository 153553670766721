import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoCloseCircle } from 'react-icons/io5';
import { NavDropdown, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import {
  BreadcrumbList,
  Header,
  SiteModal,
  SiteSpinner,
} from '../../components/common';
import { constants, Utils } from '../../helpers/';
import useLicensesStore from '../../store/LicensesStore';
import AddLicense from './addLicense/AddLicense';
import arrowLeft from '../../assets/images/icons/Arrowleft.svg';
import dotshorizontal from '../../assets/images/icons/Dotshorizontal.svg';
import { ReactComponent as LicensesIconNoRecord } from '../../assets/images/LicensesIconNoRecord.svg';
import { usePoliciesStore } from '../../store/policiesStore';
import './ManageLicenses.scss';
import { getSelectedOrganization } from '../../store/AccountStoreIDB';
import ResizableTable from '../devices/listDevice/ResizableTable';
import {
  IoIosArrowRoundDown,
  IoIosArrowRoundUp,
  IoIosSearch,
} from 'react-icons/io';
import { RxCaretSort } from 'react-icons/rx';
import { sassTrue } from 'sass';
import { CircleExclamation } from '../../assets/images';
import LoadingOverlay from 'react-loading-overlay';
import { TfiReload } from 'react-icons/tfi';
import { CiFilter } from 'react-icons/ci';
import LicenseDashboard from './LicenseDashboard';
import { BiSolidCircle } from 'react-icons/bi';
import LicenseFilters from './LicenseFilters';
import DateRangePickerWithLabels from './DateRangePickerWithLabels';
import { Tab, Tabs } from 'react-bootstrap';

const paginatedHeaderList = [
  {
    displayName:
      constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LICENSE_NAME,
    id: 'productDisplayName',
  },
  {
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LICENSE_KEY,
    id: 'licenseKey',
  },
  {
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_ADDED_BY,
    id: 'addedBy',
  },
  {
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_ADDED_DATE,
    id: 'createdDate',
  },
  {
    displayName:
      constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_ACTIVATION_DATE,
    id: 'activeDate',
  },
  {
    displayName:
      constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_EXPIRATION_DATE,
    id: 'expiryDate',
  },
  {
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_STATUS,
    id: 'licenseStatus',
  },
  {
    displayName:
      constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_ASSIGNED_DEVICE,
    id: 'deviceName',
  },
  {
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LOCATION,
    id: 'locationName',
  },
];

const DATE_FORMAT = 'MMM Do YYYY';

const ManageLicenses = () => {
  const [licensesStatus, setLicensesStatus] = useState(false);
  const [licensesUpdated, setLicensesUpdated] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [, setUserMsg] = useState('');
  const [rowData, setRowData] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const orgId = searchParams.get('orgId');
  const [showClaimLicenseModal, setShowClaimLicenseModal] = useState(false);
  const [isOpenMobilePopup, setIsOpenMobilePopup] = useState(false);

  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const [orgNameBread, setOrgNameBread] = useState('');
  const defaultPageDetails = {
    orderBy: 'createdDate',
    isAsc: true,
    page: 0,
    size: 100,
    search: false,
  };
  const [pageDetails, setPageDetails] = useState(defaultPageDetails);
  const [totalLicenses, setTotalLicenses] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [licenseList, setLicenseList] = useState([]);
  const [searchedText] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const defaultFilters = {
    licenseUpdate: [],
    applicationsLinked: [],
    licenseStatusUpdate: [],
    dateFilterUpdate: 'all',
    dateFilterRange: {},
  };
  const [filtersData, setFiltersData] = useState(defaultFilters);
  const [dateRangePickerPosition, setDateRangePickerPosition] = useState({
    top: 0,
    left: 0,
  });
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [searchText, setSearchText] = useState('');
  const [showReset, setShowReset] = useState(false);
  const [licenseNameData, setLicenseNameData] = useState([]);
  const [licenseDashboardData, setLicenseDashboardData] = useState({});
  const [currentTab, setCurrentTab] = useState('oncloud-service');

  const breadList = [
    {
      url: Utils.CheckLoggedInUserRole(orgId, orgNameBread)?.url,
      title: Utils.CheckLoggedInUserRole(orgId, orgNameBread)?.title,
    },
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${orgNameBread}&fromPush=true`,
      title: `${orgNameBread}`,
    },
    {
      url: `/licenses/listing.html?orgId=${orgId}&orgName=${orgNameBread}`,
      title: constants.LICENSES_PAGE_TITLE,
    },
  ];

  let {
    getLicenses,
    setLicenses,
    getLicensesData,
    getLicenseNames,
    setLicenseNames,
    getDashboardData,
    setDashboardData,
  } = useLicensesStore();
  const mobileBreadList = [
    {
      url: `/landing-page.html?orgId=${orgId}&orgName=${orgNameBread}&fromPush=true`,
    },
  ];

  useEffect(() => {
    const setOrg = async () => {
      const org = await getSelectedOrganization();
      setOrgNameBread(org?.orgName);
    };
    setOrg();
  }, []);

  useEffect(() => {
    setShowLoader(true);
    fetchLicenseNames();
  }, []);

  useEffect(() => {
    if (licensesUpdated === true) {
      fetchData();
      setLicensesUpdated(false);
    }
  }, [licensesUpdated]);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(filtersData), JSON.stringify(pageDetails)]);

  const mobileManageLicenses = () => {
    setIsOpenMobilePopup(true);
  };

  const fetchDashboardData = async (queryParams) => {
    try {
      setShowLoader(true);
      await setDashboardData(
        `/user/orgs/${orgId}/licenses/summary?${queryParams}`,
      );
      setLicenseDashboardData(getDashboardData());
    } catch (error) {
      setUserMsg(error);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchLicenseNames = async () => {
    try {
      setShowLoader(true);
      await setLicenseNames(`/user/orgs/${orgId}/products`);
      setLicenseNameData(getLicenseNames());
    } catch (error) {
      console.error('ERROR: ', error);
      setUserMsg(error);
    } finally {
      setShowLoader(false);
    }
  };

  // Get Licenses
  const fetchData = async (tabKey) => {
    const { page, size, orderBy, isAsc, search } = pageDetails;
    try {
      setShowLoader(true);

      let modifiedEndDate = '';
      if (filtersData?.dateFilterUpdate !== 'all' && filtersData?.endDate) {
        modifiedEndDate = new Date(filtersData?.endDate);
        modifiedEndDate.setHours(23, 59, 59, 0);
      }
      let queryParams = '';

      // Construct filter-related query parameters
      if (
        filtersData?.licenseStatusUpdate?.length ||
        filtersData?.applicationsLinked?.length ||
        filtersData?.licenseUpdate?.length ||
        filtersData?.dateFilterUpdate
      ) {
        const licenseStatusUpdate = filtersData?.licenseStatusUpdate?.join(',');
        const applicationsLinked = filtersData?.applicationsLinked?.join(',');
        const licenseUpdate = filtersData?.licenseUpdate?.join(',');

        const filterQueryParams = [
          filtersData?.dateFilterUpdate
            ? `dateColumn=${filtersData.dateFilterUpdate}`
            : null,
          filtersData?.dateFilterUpdate !== 'all' && filtersData?.startDate
            ? `startTime=${filtersData.startDate}`
            : null,
          filtersData?.dateFilterUpdate !== 'all' &&
          filtersData?.endDate &&
          modifiedEndDate
            ? `endTime=${modifiedEndDate.getTime()}`
            : null,
          licenseStatusUpdate
            ? `licenseStatuses=${encodeURIComponent(licenseStatusUpdate)}`
            : null,
          applicationsLinked ? `skus=${applicationsLinked}` : null,
          licenseUpdate ? `offeringTypes=${licenseUpdate}` : null,
        ]
          .filter(Boolean)
          .join('&');
        queryParams = filterQueryParams;
      }

      // Construct additional query parameters
      const additionalQueryParams = [
        size !== undefined && size !== false ? `size=${size}` : null,
        page !== undefined && page !== false ? `page=${page}` : null,
        orderBy !== undefined && orderBy !== false
          ? `orderBy=${orderBy}`
          : null,
        isAsc !== undefined ? `isAsc=${isAsc}` : null,
        search !== undefined && search !== false ? `search=${search}` : null,
      ]
        .filter(Boolean)
        .join('&');

      // Combine both query parameter sections
      if (additionalQueryParams) {
        queryParams = queryParams
          ? `${queryParams}&${additionalQueryParams}`
          : additionalQueryParams;
      }

      // Fetch licenses
      await setLicenses(
        `/user/orgs/${orgId}/licenses/v3?${queryParams}&serviceIdFilter=${tabKey !== undefined ? tabKey : currentTab}`,
      );
      await fetchDashboardData(queryParams);

      // Update state with licenses
      const licenses = getLicenses(false, false);
      setRowData(licenses);
      licenses?.length <= 0
        ? setLicensesStatus(true)
        : setLicensesStatus(false);
      setLicenseList(licenses);
      // setLicenseDashboardData(getDashboardData())

      // Update total licenses and pages
      const licenseData = getLicensesData();
      if (licenseData?.totalElements)
        setTotalLicenses(licenseData.totalElements);
      if (licenseData?.totalPages) setTotalPages(licenseData.totalPages);
    } catch (error) {
      console.error('ERROR: ', error);
      setUserMsg(error);
    } finally {
      setShowLoader(false);
    }
  };

  const onSort = (heading) => {
    const rawPageDetails = structuredClone(pageDetails);
    if (rawPageDetails.orderBy === heading?.id) {
      rawPageDetails.isAsc = !pageDetails.isAsc;
    } else {
      rawPageDetails.isAsc = sassTrue?.value;
    }
    rawPageDetails.orderBy = heading?.id?.toString();
    rawPageDetails.page = 0;
    setPageDetails(rawPageDetails);
  };

  const displayChildDetails = (device) => {
    return (
      <>
        {device?.isExpanded && device?.childDevices?.length
          ? device?.childDevices?.map((childDevice) => (
              <>
                <>
                  {childDevice?.childDevices?.length &&
                    childDevice?.childDevices?.map((grandChildDevice) => (
                      <>{displayChildDetails(grandChildDevice)}</>
                    ))}
                </>
              </>
            ))
          : null}
      </>
    );
  };

  const getStatusText = (license) => {
    if (!license?.licenseStatus) return '-';

    const status = licenseStatusMapText[license.licenseStatus];

    if (typeof status === 'function') {
      return status(license.activeDate);
    }

    return status || '-';
  };

  const displayDeviceRowDetails = (license) => {
    return (
      <tr key={`${searchedText}-${license?.licenseId}`}>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{license.productDisplayName || '-'}</Tooltip>}
          >
            <span className={'searchStyles'}>
              {license.productDisplayName || '-'}
            </span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{license.licenseKey || '-'}</Tooltip>}
          >
            <span className={'searchStyles'}>{license.licenseKey || '-'}</span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{license.addedBy || '-'}</Tooltip>}
          >
            <span className={'searchStyles'}>{license.addedBy || '-'}</span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {moment(parseInt(license?.createdDate)).format('ll') || '-'}
              </Tooltip>
            }
          >
            <span className={'searchStyles'}>
              {moment(parseInt(license?.createdDate)).format('ll') || '-'}
            </span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {license?.activeDate
                  ? moment(parseInt(license?.activeDate)).format('ll') || '-'
                  : '-'}
              </Tooltip>
            }
          >
            <span className={'searchStyles'}>
              {license?.activeDate
                ? moment(parseInt(license?.activeDate)).format('ll') || '-'
                : '-'}
            </span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {license?.expiryDate
                  ? moment(parseInt(license?.expiryDate)).format('ll') || '-'
                  : '-'}
              </Tooltip>
            }
          >
            <span className={'searchStyles'}>
              {license?.expiryDate
                ? moment(parseInt(license?.expiryDate)).format('ll') || '-'
                : '-'}
            </span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{getStatusText(license)}</Tooltip>}
          >
            <span className={'searchStyles'}>
              {license.licenseStatus === 'ASSIGNED_ACTIVATION_PENDING'
                ? licenseStatusMap[license.licenseStatus](license.activeDate)
                : licenseStatusMap[license.licenseStatus] || '-'}
            </span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{license.deviceName || '-'}</Tooltip>}
          >
            <span className={'searchStyles'}>{license.deviceName || '-'}</span>
          </OverlayTrigger>
        </td>
        <td>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{license.locationName || '-'}</Tooltip>}
          >
            <span className={'searchStyles'}>
              {license.locationName || '-'}
            </span>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  const displayGatewayDetails = () => {
    return (
      <>
        {!licenseList?.length && showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="loader-container">
              <SiteSpinner width="60px" height="60px" />
            </td>
          </tr>
        ) : licenseList?.length ? (
          <>
            {licenseList?.map((license, index) => (
              <>
                {/* {index < (pageDetails?.page + 1) * 5
                  ? displayDeviceRowDetails(license)
                  : null} */}
                {displayDeviceRowDetails(license)}
                {displayChildDetails(license)}
              </>
            ))}
          </>
        ) : !showLoader ? (
          <tr>
            <td rowSpan={10} colSpan={8} className="no-device-container">
              <img
                alt="exclamation"
                src={CircleExclamation}
                width={'60px'}
                height={'60px'}
              />
              <div className="no-device-content mt-3">
                <div className="no-device-heading">
                  {constants.SCAN_NETWORK_NO_DEVICE_TITLE}
                </div>
                <div className="no-device-text">
                  {constants.DEVICES_TABLE_NO_LICENSES_TEXT}
                </div>
              </div>
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  const displayHeaderDetails = (headerList) => {
    return (
      <>
        <tr>
          {headerList?.map((heading) => (
            <th>
              <div className={`${heading.id} column-saparator`}>
                {heading?.displayName}
                {heading?.id === 'arrow' ||
                heading?.id ===
                  'productDisplayName' ? null : pageDetails.orderBy ===
                    heading?.id && pageDetails.isAsc ? (
                  <IoIosArrowRoundDown
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                ) : pageDetails.orderBy === heading?.id &&
                  !pageDetails.isAsc ? (
                  <IoIosArrowRoundUp
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                ) : (
                  <RxCaretSort
                    size={20}
                    color="#0000004A"
                    role="button"
                    onClick={() => onSort(heading)}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </>
    );
  };

  const onchangePageNo = (pageNo) => {
    const rawPageDetails = structuredClone(pageDetails);
    rawPageDetails.page = pageNo;
    setPageDetails(rawPageDetails);
  };

  const onClickReset = () => {
    setSearchText('');
    setPageDetails(defaultPageDetails);
    setFiltersData(defaultFilters);
    setShowReset(true);
  };

  const licenseStatusMap = {
    UNASSIGNED: (
      <div className="status-field">
        <BiSolidCircle className="status-indicator status-available" />
        <div className="status-value">
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE}
        </div>
      </div>
    ),
    ASSIGNED_ACTIVATION_PENDING: (effectiveStartUnixTime) => (
      <div className="status-field">
        <BiSolidCircle className="status-indicator status-inactive" />
        <div className="status-value">
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
            ' ' +
            moment(effectiveStartUnixTime).format(DATE_FORMAT)}
        </div>
      </div>
    ),
    ACTIVE: (
      <div className="status-field">
        <BiSolidCircle className="status-indicator status-active" />
        <div className="status-value">
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE}
        </div>
      </div>
    ),
    ACTIVE_UNASSIGNED: (
      <div className="status-field">
        <BiSolidCircle className="status-indicator status-available" />
        <div className="status-value">
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE}
        </div>
      </div>
    ),
    EXPIRING_SOON: (
      <div className="status-field">
        <BiSolidCircle className="status-indicator status-expiring-soon" />
        <div className="status-value">
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON}
        </div>
      </div>
    ),
    EXPIRED: (
      <div className="status-field">
        <BiSolidCircle className="status-indicator status-expired" />
        <div className="status-value">
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED}
        </div>
      </div>
    ),
  };

  const licenseStatusMapText = {
    UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    ASSIGNED_ACTIVATION_PENDING: (effectiveStartUnixTime) =>
      constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
      ' ' +
      moment(effectiveStartUnixTime).format(DATE_FORMAT),
    ACTIVE: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE,
    ACTIVE_UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    EXPIRING_SOON: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON,
    EXPIRED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED,
  };

  const LoaderOverlay = () => {
    return <div className="position-fixed l-48 loader-style"></div>;
  };

  const handleGetPosition = (pos) => {
    setDateRangePickerPosition(pos);
    setShowDateRangePicker(true);
  };

  const handleOkBtnClick = (value) => {
    setSelectedDateRange({
      startDate: moment(new Date(value.startDate).getTime()).format(
        'MMM DD, YYYY',
      ),
      endDate: moment(new Date(value.endDate).getTime()).format('MMM DD, YYYY'),
    });
    setShowDateRangePicker(false);
  };

  return (
    <div className="App manage-licenses">
      <Header showHeader={true} className="desktop" />
      <div className="breadcrumbsResponsive mobile">
        <div className="text-start me-auto navbar-brand arrow">
          <img src={arrowLeft} alt=""></img>
          <spam
            className="backText"
            onClick={() => navigate(mobileBreadList[0]?.url)}
          >
            {constants.BREADCRUMB_BACK}
          </spam>
        </div>
        {getCustomerOrgPolicies()?.add_license_customer_org && (
          <Nav>
            <NavDropdown
              className="mobile"
              title={
                <div>
                  <img className="dots" src={dotshorizontal} alt=""></img>
                </div>
              }
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href="#" onClick={mobileManageLicenses}>
                <div>{constants.LICENSE_ADD_LICENSE_TITLE}</div>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
      </div>
      <LoadingOverlay active={showLoader} spinner={LoaderOverlay()}>
        <div className="paginated-license-page">
          <div className="paginated-licenses">
            <div className="licenses-header">
              <div className="licenses-title">
                {constants.LICENSES_PAGE_TITLE}
              </div>
              <BreadcrumbList
                list={Utils.CheckBreadcrumbForUserRole(breadList)}
              />
            </div>
            <LicenseDashboard licenseDashboardData={licenseDashboardData} />
            <Tabs
              defaultActiveKey="oncloud-service"
              transition={false}
              id="noanim-tab-example"
              className="license-tab mb-3 mt-3"
              onSelect={(e) => {
                setCurrentTab(e);
                fetchData(e);
              }}
            >
              <Tab eventKey="oncloud-service" title="OnCloud"></Tab>
              <Tab eventKey="sightmind-service" title="SightMind"></Tab>
              <Tab eventKey="dmpro-service" title="DMPro"></Tab>
            </Tabs>
            <div className="licenses-filters">
              <div className="left-side">
                <AddLicense
                  isOpenMobilePopup={isOpenMobilePopup}
                  handleOpenMobilePopup={setIsOpenMobilePopup}
                  setShowClaimLicenseModal={setShowClaimLicenseModal}
                  showClaimLicenseModal={showClaimLicenseModal}
                  reloadData={() => {
                    setLicensesUpdated(true);
                  }}
                />
              </div>
              <div className="right-side">
                <div className="search-container">
                  <IoIosSearch
                    className="search-icon"
                    size={24}
                    role="button"
                    onClick={() => {
                      const rawPageDetails =
                        structuredClone(defaultPageDetails);
                      if (searchText !== '') {
                        rawPageDetails.search = searchText;
                      } else {
                        rawPageDetails.search = false;
                      }
                      setPageDetails(rawPageDetails);
                    }}
                  />
                  <input
                    type={'text'}
                    placeholder={'Search'}
                    className="search-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e?.target?.value)}
                    onKeyDown={(e) => {
                      const rawPageDetails =
                        structuredClone(defaultPageDetails);
                      if (e.key === 'Enter' && e?.target?.value !== '') {
                        rawPageDetails.search = e?.target?.value;
                        setPageDetails(rawPageDetails);
                      } else if (e?.target?.value === '' && e.key === 'Enter') {
                        setSearchText('');
                        rawPageDetails.search = false;
                        setPageDetails(rawPageDetails);
                      }
                    }}
                  />
                  {searchText ? (
                    <IoCloseCircle
                      className="search-close"
                      size={16}
                      onClick={() => {
                        const rawPageDetails = structuredClone(pageDetails);
                        setSearchText('');
                        rawPageDetails.search = false;
                        rawPageDetails.page = 0;
                        setPageDetails(rawPageDetails);
                      }}
                      color="#747E87"
                      role="button"
                    />
                  ) : null}
                </div>
                <div
                  className="reload-filter-edit"
                  role="button"
                  onClick={() => {
                    setShowFilterModal(true);
                    // setUpdateUserMessage(null);
                  }}
                >
                  <CiFilter size={20} />
                  {constants.LICENSES_HEADER_BUTTON_FILTER}
                </div>
                <div
                  className="reload-filter-edit"
                  role="button"
                  onClick={() => onClickReset()}
                >
                  <TfiReload size={16} />
                  {constants.LICENSES_HEADER_BUTTON_RELOAD}
                </div>
              </div>
            </div>
            {rowData?.length ? (
              <>
                <ResizableTable
                  widths={[242, 280, 166, 140, 140, 140, 130, 150, 170]}
                  minWidths={[168, 170, 130, 135, 135, 135, 100, 145, 120]}
                  minWidth={29}
                  disabledColumns={[]}
                  displayHeaderDetails={() =>
                    displayHeaderDetails(paginatedHeaderList)
                  }
                  displayGatewayDetails={() => displayGatewayDetails()}
                  totalPages={totalPages}
                  totalElements={totalLicenses}
                  pageDetails={pageDetails}
                  showPagination={true}
                  onchangePageNo={onchangePageNo}
                  license={true}
                />
              </>
            ) : showLoader ? (
              <div className="no-data-available">
                <div className="position-absolute l-48 t-52">
                  <SiteSpinner height="50px" width="50px" />
                  <div className="mt-2 text-dark">{constants.LOADING}</div>
                </div>
              </div>
            ) : (
              licensesStatus && (
                <div className="no-devices-container">
                  <div className="image-wrapper">
                    <div className="license-icon">
                      <LicensesIconNoRecord />
                    </div>
                  </div>
                  <div>{constants.NO_LICENSE_TITLE}</div>
                </div>
              )
            )}
            <SiteModal
              modalTitle=""
              modalHeader={true}
              showModal={showFilterModal}
              hideModal={() => {
                setShowFilterModal(false);
                setShowDateRangePicker(false);
              }}
              classes="license-filter-modal"
              key="license-filter-modal"
              wrapperClass="license-filter-modal"
              size={'lg'}
              backdrop={true}
            >
              <LicenseFilters
                selectedDateRange={selectedDateRange}
                closeModal={() => {
                  setShowFilterModal(false);
                  setShowDateRangePicker(false);
                }}
                onSetFiltersData={(data) => {
                  const rawPageDetails = structuredClone(pageDetails);
                  rawPageDetails.page = 0;
                  setPageDetails(rawPageDetails);
                  setFiltersData(data);
                }}
                filtersData={filtersData}
                onGetPosition={handleGetPosition}
                onSelectedDateRange={setSelectedDateRange}
                onChangeShowDateRangePicker={setShowDateRangePicker}
                setShowReset={setShowReset}
                showReset={showReset}
                licenseNameData={licenseNameData}
                showDateRangePicker={showDateRangePicker}
                setShowDateRangePicker={setShowDateRangePicker}
              />
              {showDateRangePicker && (
                <div
                  className="flex flex-col items-center p-4"
                  style={{
                    position: 'fixed',
                    top: `${dateRangePickerPosition.top}px`,
                    left: `${dateRangePickerPosition.left}px`,
                    zIndex: 10000,
                  }}
                >
                  <DateRangePickerWithLabels
                    passedDateRange={selectedDateRange}
                    okBtnClick={handleOkBtnClick}
                  />
                </div>
              )}
            </SiteModal>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default ManageLicenses;
