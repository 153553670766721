import { useState } from "react";
import { constants } from "../../helpers";
import { PiWarning, PiWarningCircle } from "react-icons/pi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { ReactComponent as CircledInfoIcon } from "../../assets/images/information.svg";

const LicenseDashboard = ({ licenseDashboardData }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div
      className="total-license-info"
      onClick={() => setShowDetails(!showDetails)}
    >
      <div className="info-section">
        <div className="section-sort-info">
          <div className="section-label">
            {constants.LICENSES_PAGE_INFO_TOTAL}
          </div>
          <div className="section-value">{licenseDashboardData?.totalCount}</div>
        </div>
        <div
          className={`${showDetails ? "show" : ""
            } detailed-info-section-list mt-3`}
        >
          {licenseDashboardData?.totalBreakdown?.['oncloud-service'] !== undefined &&
            licenseDashboardData?.totalBreakdown?.['oncloud-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">OnCloud</div>
              <div className="detailed-info-value">{licenseDashboardData?.totalBreakdown?.['oncloud-service']}</div>
            </div>}
          {licenseDashboardData?.totalBreakdown?.['sightmind-service'] !== undefined &&
            licenseDashboardData?.totalBreakdown?.['sightmind-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">SightMind</div>
              <div className="detailed-info-value">{licenseDashboardData?.totalBreakdown?.['sightmind-service']}</div>
            </div>}
          {licenseDashboardData?.totalBreakdown?.['dmpro-service'] !== undefined &&
            licenseDashboardData?.totalBreakdown?.['dmpro-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">DMPro</div>
              <div className="detailed-info-value">{licenseDashboardData?.totalBreakdown?.['dmpro-service']}</div>
            </div>}
        </div>
      </div>
      <div className="info-section">
        <div className="section-sort-info">
          <div className="section-label">
            <CircledInfoIcon className="active-icon" />
            {constants.LICENSES_PAGE_INFO_AVAILABLE}
          </div>
          <div className="section-value">{licenseDashboardData?.availableCount}</div>
        </div>
        <div
          className={`${showDetails ? "show" : ""
            } detailed-info-section-list mt-3`}
        >
          {licenseDashboardData?.availableBreakdown?.['oncloud-service'] !== undefined &&
            licenseDashboardData?.availableBreakdown?.['oncloud-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">OnCloud</div>
              <div className="detailed-info-value">{licenseDashboardData?.availableBreakdown?.['oncloud-service']}</div>
            </div>}
          {licenseDashboardData?.availableBreakdown?.['sightmind-service'] !== undefined &&
            licenseDashboardData?.availableBreakdown?.['sightmind-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">SightMind</div>
              <div className="detailed-info-value">{licenseDashboardData?.availableBreakdown?.['sightmind-service']}</div>
            </div>}
          {licenseDashboardData?.availableBreakdown?.['dmpro-service'] !== undefined &&
            licenseDashboardData?.availableBreakdown?.['dmpro-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">DMPro</div>
              <div className="detailed-info-value">{licenseDashboardData?.availableBreakdown?.['dmpro-service']}</div>
            </div>}
        </div>
      </div>
      <div className="info-section">
        <div className="section-sort-info">
          <div className="section-label">
            <IoCheckmarkCircleOutline className="checked-icon" />
            {constants.LICENSES_PAGE_INFO_ACTIVE}
          </div>
          <div className="section-value">{licenseDashboardData?.activeCount}</div>
        </div>
        <div
          className={`${showDetails ? "show" : ""
            } detailed-info-section-list mt-3`}
        >
          {licenseDashboardData?.activeBreakdown?.['oncloud-service'] !== undefined &&
            licenseDashboardData?.activeBreakdown?.['oncloud-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">OnCloud</div>
              <div className="detailed-info-value">{licenseDashboardData?.activeBreakdown?.['oncloud-service']}</div>
            </div>}
          {licenseDashboardData?.activeBreakdown?.['sightmind-service'] !== undefined &&
            licenseDashboardData?.activeBreakdown?.['sightmind-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">SightMind</div>
              <div className="detailed-info-value">{licenseDashboardData?.activeBreakdown?.['sightmind-service']}</div>
            </div>}
          {licenseDashboardData?.activeBreakdown?.['dmpro-service'] !== undefined &&
            licenseDashboardData?.activeBreakdown?.['dmpro-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">DMPro</div>
              <div className="detailed-info-value">{licenseDashboardData?.activeBreakdown?.['dmpro-service']}</div>
            </div>}
        </div>
      </div>
      <div className="info-section">
        <div className="section-sort-info">
          <div className="section-label">
            <PiWarning className="warning-icon" />
            {constants.LICENSES_PAGE_INFO_EXPIRING_SOON}
          </div>
          <div className="section-value">{licenseDashboardData?.expiringSoonCount}</div>
        </div>
        <div
          className={`${showDetails ? "show" : ""
            } detailed-info-section-list mt-3`}
        >
          {licenseDashboardData?.expiringSoonBreakdown?.['oncloud-service'] !== undefined &&
            licenseDashboardData?.expiringSoonBreakdown?.['oncloud-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">OnCloud</div>
              <div className="detailed-info-value">{licenseDashboardData?.expiringSoonBreakdown?.['oncloud-service']}</div>
            </div>}
          {licenseDashboardData?.expiringSoonBreakdown?.['sightmind-service'] !== undefined &&
            licenseDashboardData?.expiringSoonBreakdown?.['sightmind-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">SightMind</div>
              <div className="detailed-info-value">{licenseDashboardData?.expiringSoonBreakdown?.['sightmind-service']}</div>
            </div>}
          {licenseDashboardData?.expiringSoonBreakdown?.['dmpro-service'] !== undefined &&
            licenseDashboardData?.expiringSoonBreakdown?.['dmpro-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">DMPro</div>
              <div className="detailed-info-value">{licenseDashboardData?.expiringSoonBreakdown?.['dmpro-service']}</div>
            </div>}
        </div>
      </div>
      <div className="info-section">
        <div className="section-sort-info">
          <div className="section-label">
            <PiWarningCircle className="error-icon" />
            {constants.LICENSES_PAGE_INFO_EXPIRED}
          </div>
          <div className="section-value">{licenseDashboardData?.expiredCount}</div>
        </div>
        <div
          className={`${showDetails ? "show" : ""
            } detailed-info-section-list mt-3`}
        >
          {licenseDashboardData?.expiredBreakdown?.['oncloud-service'] !== undefined &&
            licenseDashboardData?.expiredBreakdown?.['oncloud-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">OnCloud</div>
              <div className="detailed-info-value">{licenseDashboardData?.expiredBreakdown?.['oncloud-service']}</div>
            </div>}
          {licenseDashboardData?.expiredBreakdown?.['sightmind-service'] !== undefined &&
            licenseDashboardData?.expiredBreakdown?.['sightmind-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">SightMind</div>
              <div className="detailed-info-value">{licenseDashboardData?.expiredBreakdown?.['sightmind-service']}</div>
            </div>}
          {licenseDashboardData?.expiredBreakdown?.['dmpro-service'] !== undefined &&
            licenseDashboardData?.expiredBreakdown?.['dmpro-service'] !== null &&
            <div className="detailed-info-section">
              <div className="detailed-info-label">DMPro</div>
              <div className="detailed-info-value">{licenseDashboardData?.expiredBreakdown?.['dmpro-service']}</div>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default LicenseDashboard;
