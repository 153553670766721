import { useEffect, useRef, useState } from "react";
import {
  IconArrowChevronDown,
  IconArrowChevronUp,
} from "../../assets/images/index";
import "./Dropdown.scss";

const Dropdown = ({
  items,
  defaultValue,
  selected,
  onSelect,
  renderCustomField,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="dropdown-container"
      style={{ width: `${width}px` }}
    >
      <div
        className={`dropdown ${isOpen && "opened"}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {renderCustomField ? (
          renderCustomField()
        ) : (
          <div className="dropdown-text-field">
            {selected ? selected.value : defaultValue}
          </div>
        )}
        {isOpen ? <IconArrowChevronUp /> : <IconArrowChevronDown />}
      </div>
      {isOpen && (
        <div className="dropdown-items">
          {items.map((item) => (
            <div
              className={`dropdown-item ${item.id === selected?.id && "selected"}`}
              onClick={() => {
                onSelect(item.id);
                setIsOpen(false);
              }}
              key={item.id}
            >
              {item.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
