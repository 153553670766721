import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import axios from 'axios';
import {
  constants,
  Utils,
  ManageMqttData,
  AppDefaults,
  roles,
} from '../../helpers';
import {
  app_version,
  checkNotificationSupport,
} from '../../helpers/commonUtils';
import { ReactComponent as BrandLogoSvg } from '../../assets/images/BrandLogo.svg';
import { ReactComponent as CloudPortalWhiteLogo } from '../../assets/images/CloudPortalWhiteLogo.svg';
import { LiaUserCircleSolid } from 'react-icons/lia';
import {
  HiOutlineUser,
  HiOutlineLogout,
  HiOutlineOfficeBuilding,
  HiOutlineLockClosed,
} from 'react-icons/hi';
import { TbSettings } from 'react-icons/tb';
import { BsFillGrid3X3GapFill, BsChevronRight } from 'react-icons/bs';
import { ReactComponent as LicensesIcon } from '../../assets/images/licenses_icon.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/users_icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location_icon.svg';
import { ReactComponent as DeviceIcon } from '../../assets/images/device_icon.svg';

import { useKeycloak } from '@react-keycloak/web';
import { useCart } from '../../store/CartStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import greaterThanIcon from '../../assets/images/icons/greaterThanIcon.svg';
import headerMenu from '../../assets/images/icons/Menu.svg';
import { IoHelpCircleOutline } from 'react-icons/io5';

import {
  clearLiveStreams,
  clearMQTTPeerConnections,
  clearRTCPeerConnections,
  clearRemoteStreams,
  clearWSSConnections,
  getMQTTConnection,
  getPlatformInfo,
  setPlatformInfo,
  getWSSConnection,
  resetPlatformInfo,
} from '../../store/reducers/StreamingReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAccountId,
  // getMqttUpdateStatus,
  getAllMqttDataFromResponse,
  // setMqttUpdateStatusFromResponse,
  getAccountId,
  getAllAreas,
  setAllAreas,
  setSubscribeAreasRequest,
  getSubscribeAreasRequest,
  setKeepAliveRequest,
  getKeepAliveRequest,
  // setSelectedOrganization,
  // getSelectedOrganization
} from '../../store/reducers/AccountReducer';
import {
  checkMQTTConnection,
  connectWithMQTT,
  mqttDisconnectRequest,
  mqttPublish,
  mqttSubscribe,
  subscribeWithAccountId,
  subscribeWithOrgIds,
} from '../../utils/connection/mqttConnection';
import { connectWithWebSocket } from '../../utils/connection/wssConnection';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { v4 as uuidv4 } from 'uuid';
import { useNotificationStore } from '../../store/CreateNotificationStore';
import useEventsStore from '../../store/EventsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { useModuleStore } from '../../store/ModuleStatusStore';
import { ReactComponent as ShockwaveBrandLogoSvg } from '../../assets/images/ShockwaveBrandLogo.svg';
import { useAppsStore } from '../../store/AppsStore';
import TermConditionPolicyModal from './TermConditionPolicyModal';
import { clearInterval, setInterval } from 'worker-timers';
import {
  getCustomerOrgData,
  resetCustomerOrgData,
  setCustomerOrgData,
} from '../../store/OrganizationsStoreIDB';
import {
  getLoggedInUserRole,
  getOrgInfo,
  getSelfAccountData,
  resetSelfAccountData,
} from '../../store/reducers/OrganizationsReducer';
import {
  getSelectedOrganization,
  setSelectedOrganization,
} from '../../store/AccountStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

function Header({
  backgroundColor,
  numOfICartItems,
  showCart = true,
  showNavigation,
  isAccountScreen = false,
  cartFetch = true,
  breadcrumbData = [],
  currentDeviceId = false,
  showHeader = true,
  onPushRecieve,
  isFromVideoWall = false,
}) {
  const intervalRef = useRef();
  const orgInfo = useSelector(getOrgInfo);
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const [dynamicApp, setDynamicApp] = useState({});
  const [searchParams] = useSearchParams();
  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);
  const getModuleStatus = useModuleStore((state) => state.getModuleStatus);
  const setModuleStatus = useModuleStore((state) => state.setModuleStatus);
  setModuleStatus(Utils.getModuleStatus());
  const moduleStatus = getModuleStatus();
  const dispatch = useDispatch();
  // TODO: read these two from the store that has them
  let orgId = searchParams.get('orgId');
  let orgName = searchParams.get('orgName');
  const [url, setUrl] = useState(null);
  const [hasNotification, setHasNotification] = useState(false);
  const [numOfCartItems, setNumOfCartItems] = useState(numOfICartItems);
  const [custOrgData, setCustOrgData] = useState([]);
  const [areasFinal, setAreasFinal] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const navigate = useNavigate();
  const mqttConnection = useSelector(getMQTTConnection);
  const wssConnection = useSelector(getWSSConnection);
  const accountId = useSelector(getAccountId);
  // const { resetLiveViewDevices } = useCustomerOrgDevices();
  //=== Store get/set actions
  const cart = useCart((state) => state.cart);
  let pendingOrder = useCart((state) => state.isOrderPending);
  const getCartTotalQuantity = useCart((state) => state.getCartTotalQuantity);
  const deleteCart = useCart((state) => state.deleteCart);
  const deleteOrder = useCart((state) => state.deleteOrder);
  const deleteOrgId = useCart((state) => state.deleteOrgId);
  const setOrderPending = useCart((state) => state.setOrderPending);
  const setOrder = useCart((state) => state.setOrder);
  const setOrgId = useCart((state) => state.setOrgId);

  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const getMQTTConnectionStatus = useSelector(getMQTTConnection);
  const platformDetails = useSelector(getPlatformInfo);
  const getSubscribeAreas = useSelector(getSubscribeAreasRequest);
  const getKeepAlive = useSelector(getKeepAliveRequest);
  const setPingApiCallTime = useLoggedInUserData(
    (state) => state.setPingApiCallTime,
  );
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();

  const allAreasStore = useSelector(getAllAreas);

  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const setCustomerOrgData = useOrganizations(
  //   (state) => state.setCustomerOrgData
  // );
  // const resetCustomerOrgData = useOrganizations(
  //   (state) => state.resetCustomerOrgData
  // );
  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData,
  );
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations,
  );
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies,
  );
  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData,
  );
  const setUserPushData = useOrganizations((state) => state.setUserPushData);
  const selfAccountData = useSelector(getSelfAccountData);
  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData,
  );
  const { getState } = devicesMQTTStore;
  const state = getState();
  const { setSelectedEventStore, setEventFromSearch, setEventCVRMode } =
    useEventsStore();
  const [selOrg, setSelOrg] = useState('');
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const resetSelfAccount = useOrganizations((state) => state.resetSelfAccount);
  const getPingApiCallTime = useLoggedInUserData(
    (state) => state.getPingApiCallTime,
  );
  let sessionTimeoutValue =
    loggedInUserData && loggedInUserData?.sessionTimeout
      ? loggedInUserData?.sessionTimeout == -1
        ? AppDefaults.DEFAULT_TIMER_TIME
        : parseInt(loggedInUserData?.sessionTimeout) * 60000
      : AppDefaults.DEFAULT_TIMER_TIME;

  const intervalInMillis = Math.ceil(sessionTimeoutValue / 3);

  let lastActivityTimestamp = new Date().getTime();

  useEffect(() => {
    const status = localStorage.getItem('isSession');
    sessionTimeoutValue =
      loggedInUserData && loggedInUserData?.sessionTimeout
        ? loggedInUserData?.sessionTimeout == -1
          ? AppDefaults.DEFAULT_TIMER_TIME
          : parseInt(loggedInUserData?.sessionTimeout) * 60000
        : AppDefaults.DEFAULT_TIMER_TIME;
    const token = localStorage.getItem('vmsAuthToken');
    if (status != 'undefined' && status === '0') {
      if (token != 'null' && token != 'undefined' && token != '') {
        if (
          !loggedInUserData?.email ||
          (loggedInUserData && loggedInUserData?.sessionTimeout == -1)
        ) {
          return;
        }
        localStorage.setItem('isSession', 1);
        localStorage.setItem('isSession', 1);
        setPingApiCallTime(lastActivityTimestamp);
        setTimeout(() => {
          if (!axios.defaults.headers.common['Authorization']) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          }
          OnIdleTrack(lastActivityTimestamp);
        }, 2000);
      }
    }
  }, [loggedInUserData]);

  const handleMouse = () => {
    const token = localStorage.getItem('vmsAuthToken');
    if (token == 'null' || token == 'undefined' || token == '') {
      return;
    }
    lastActivityTimestamp = new Date().getTime();
    const lastPingDurationInMins =
      (lastActivityTimestamp - getPingApiCallTime()) / 60000;
    if (lastPingDurationInMins >= intervalInMillis / 60000) {
      // Invoke API
      //TODO Delete Later
      console.log('KeepAlive: Handle Mouse ', lastActivityTimestamp);
      setPingApiCallTime(lastActivityTimestamp);
      OnIdleTrack(lastActivityTimestamp);
    }
  };

  useEffect(() => {
    const idPing = setInterval(() => {
      const lastActiveDurationInMins =
        (new Date().getTime() - lastActivityTimestamp) / 1000 / 60;
      // Check if last activity was done in last 10 mins or not
      //TODO DELETE LATER
      console.log('KeepAlive: called => ', lastActiveDurationInMins);
      if (lastActiveDurationInMins <= intervalInMillis / 60000) {
        // Invoke API
        //TODO DELETE LATER
        console.log('KeepAlive: activeTimer => ', lastActivityTimestamp);
        setPingApiCallTime(lastActivityTimestamp);
        OnIdleTrack(lastActivityTimestamp);
      }
    }, intervalInMillis - 60000);
    intervalRef.current = idPing;
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [loggedInUserData]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouse);

    return () => {
      document.removeEventListener('mousemove', handleMouse);
    };
  }, [loggedInUserData]);

  useEffect(() => {
    if (orgDetails) {
      const policies = orgDetails?.policies;
      const updatedPoliciesData = Utils.mapUserPolicies(
        DefaultPolicies,
        policies,
      );
      setCustomerOrgPolicies(updatedPoliciesData);
    }
  }, [orgDetails]);

  const OnIdleTrack = async (timestamp) => {
    const token = localStorage.getItem('vmsAuthToken');
    if (token == 'null' || token == 'undefined' || token == '') {
      return;
    } else if (loggedInUserData && loggedInUserData?.sessionTimeout == -1) {
      return;
    }
    await axios
      .get('partner/ping', {
        params: {
          lastActivityTime: Math.ceil(timestamp / 1000),
        },
        ...Utils.requestHeader(),
      })
      .then((res) => {
        const responseData = res?.data;
        if (responseData?.meta?.code === 401) {
          setPingApiCallTime(0);
          clearInterval(intervalRef?.current);
          invalidateSessionApi();
        }
      });
  };

  const breadList = [
    {
      url: `/locations/manage.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE,
      activeUrl: '/locations/manage.html',
      icon: <LocationIcon></LocationIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_location,
    },
    {
      url: `/devices/listing.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
      activeUrl: '/devices/listing.html',
      icon: <DeviceIcon></DeviceIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_device,
    },
    {
      url: `/users/manage.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.USERS_PAGE_TITLE,
      activeUrl: '/users/manage.html',
      icon: <UsersIcon></UsersIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_all_accounts,
    },
    {
      url: `/licenses/listing.html?orgId=${orgId ? orgId : orgInfo?.orgId}`,
      title: constants.LICENSES_PAGE_TITLE,
      activeUrl: '/licenses/listing.html',
      icon: <LicensesIcon></LicensesIcon>,
      viewPolicy: getCustomerOrgPolicies()?.view_license_key,
    },
  ];
  const customersUrl = '/customers/manage.html';
  const managePartnerUrl = '/managepartnerusers.html';
  const getApps = useAppsStore((state) => state.getApps);
  let appList = useAppsStore((state) => state.appList);
  let helpData = useAppsStore((state) => state.helpData);
  const [customerOrgDetails, setCustomerOrgDetails] = useState();
  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustomerOrgDetails(orgs || []);
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000,
  );

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
      // if (data.key === 'allMqttDataFromResponse') {
      //   await loadAllMqttData();
      // }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();
    debouncedLoadSelectedOrgData();
    // loadAllMqttData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [
    debouncedLoadCustomerOrgData,
    debouncedLoadSelectedOrgData,
    // loadAllMqttData
  ]);

  useEffect(() => {
    const setOrg = async () => {
      if (customerOrgDetails?.length) {
        const userIndex = customerOrgDetails?.find(
          (user) => user.orgId === orgId,
        );
        await setSelectedOrganization(userIndex);
      }
    };
    setOrg();
  }, [customerOrgDetails]);

  useEffect(() => {
    const tokenVms = localStorage.getItem('vmsAuthToken');
    setTimeout(() => {
      if ((tokenVms == 'null' || tokenVms == 'undefined') && !isAccountScreen) {
        navigate(`/`);
      }
    }, 3000);

    if (window.performance) {
      if (performance.navigation.type == 1) {
        dispatch(setSubscribeAreasRequest(false));
        dispatch(setKeepAliveRequest(false));
      }
    }
  }, []);

  useEffect(() => {
    if (
      Array.isArray(customerOrgDetails) &&
      customerOrgDetails?.length > 0 &&
      getMQTTConnectionStatus
    ) {
      subscribeWithOrgIds(customerOrgDetails);

      const deviceSubscription = {
        topic: `d/notify/${accountId}/${state.getSessionId()}`,
        qos: 0,
      };

      // Subscribe  to the device topic
      mqttSubscribe(deviceSubscription);

      // if (!getSubscribeAreas && !getKeepAlive) {
      //   const devices = getCustomerOrgData()[0]?.devices;
      //   const areas = [...new Set(devices?.map((obj) => obj.areaId))];
      //   setAreasFinal(areas);
      //   dispatch(setAllAreas(areas));
      //   if (areas.length) {
      //     subscribeRequest(areas);

      //     if (!state.getSessionId()) {
      //       state.setSessionId(uuidv4());
      //     }

      //     //Retrieve keep alive session every 90 seconds
      //     setInterval(() => {
      //       keepAliveRequest(areas);
      //     }, 90000);
      //   }
      // } else if (!getSubscribeAreas) {
      //   if (allAreasStore.length) {
      //     subscribeRequest(allAreasStore);
      //   }
      // } else if (!getKeepAlive) {
      //   if (allAreasStore.length) {
      //     keepAliveRequest(allAreasStore);
      //   }
      // }
    }
    if (loggedInUserData) {
      subscribeWithAccountId(loggedInUserData?.accountId);
      dispatch(setAccountId(loggedInUserData?.accountId));
    }
  }, [
    getMQTTConnectionStatus,
    loggedInUserData,
    getSubscribeAreas,
    getKeepAlive,
    customerOrgDetails?.length > 0,
  ]);

  // TODO: Delete later
  // useEffect(() => {
  //   if (allAreasStore?.length) {
  //     keepAliveRequest(allAreasStore);
  //   }
  // }, []);

  const getTopic = (areas) => {
    return areas?.map((area) => {
      return `a/rt-events/${area}`;
    });
  };

  const subscribeRequest = (areas) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    dispatch(setSubscribeAreasRequest(true));

    // Send the request
    const context = {
      topic: getTopic(areas),
      payload: JSON.stringify({
        tid: `${tid}`,
        // to: `${deviceId}`,
        from: `${accountId}`,
        msg: {
          resource: 'camera/events/live-events',
          action: 'add',
          properties: {
            sessionId: sessionId,
            events: [
              {
                detection: [
                  'motion',
                  'person',
                  'vehicle',
                  'lp',
                  'face',
                  'audio',
                  'SoundRecognition',
                ],
                notifications: [
                  'queue-threshold',
                  'tamper',
                  'defocus',
                  'shock',
                ],
                stats: ['queue-count', 'person-count', 'vehicle-count'],
              },
            ],
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };

    mqttPublish(context);
  };

  const keepAliveRequest = useCallback((areas) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    dispatch(setKeepAliveRequest(true));

    // Send the request
    const context = {
      topic: getTopic(areas),
      payload: JSON.stringify({
        tid: `${tid}`,
        // to: `${deviceId}`,
        from: `${accountId}`,
        msg: {
          action: 'set',
          resource: 'camera/events/live-events',
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };

    mqttPublish(context);
  }, []);

  const deleteRequest = (areas) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: getTopic(areas),
      payload: JSON.stringify({
        tid: `${tid}`,
        // to: `${deviceId}`,
        from: `${accountId}`,
        msg: {
          resource: 'camera/events/live-events',
          action: 'delete',
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };

    mqttPublish(context);
  };

  //   TODO: Below code is in observation
  useEffect(() => {
    const tokenWeb = localStorage.getItem('vmsAuthToken');
    if (!tokenWeb || tokenWeb === 'undefined') {
      return;
    }
    const isMqttTokenExpired =
      platformDetails?.mqtt?.expiry * 1000 < new Date().getTime();

    if (
      !checkMQTTConnection() &&
      accountId &&
      platformDetails &&
      platformDetails?.mqtt?.token &&
      !isMqttTokenExpired
    ) {
      connectWithMQTT(accountId);
    } else if (
      accountId &&
      ((platformDetails &&
        (isMqttTokenExpired || !platformDetails?.mqtt?.token)) ||
        !platformDetails)
    ) {
      getLatestPlatformData();
    }
  }, [accountId, checkMQTTConnection(), platformDetails]);

  const getLatestPlatformData = () => {
    axios
      .get(`/partner/platform`, {
        withCredentials: true,
        ...Utils.requestHeader(),
      })
      .then((response) => {
        if (response?.data?.data) {
          dispatch(setPlatformInfo(response?.data?.data));
        }
      });
  };
  // useEffect(() => {
  //   if (!wssConnection && getPlatformDetails) {
  //     connectWithWebSocket(getPlatformDetails);
  //   }
  // }, [getPlatformDetails, wssConnection]);

  useEffect(() => {
    if (numOfICartItems === null || numOfICartItems === undefined) {
      let currentNumOfCartItems = getCartTotalQuantity();

      if (currentNumOfCartItems > 0) {
        setNumOfCartItems(currentNumOfCartItems);
      }
    } else {
      setNumOfCartItems(numOfICartItems);
    }
  }, [getCartTotalQuantity, numOfICartItems, orgId]);

  useEffect(() => {
    let requestUrl = location.pathname.replace(/\/+$/, '');
    setUrl(requestUrl);
  }, [location]);

  useEffect(() => {
    if (selfAccountData?.orgType) {
      setLoggedInUserData(selfAccountData);
    }
  }, [selfAccountData]);

  const getUrl = (url) => {
    return url?.replace(/\/[^/]+\.html$/, '');
  };

  //=== Update the device data based on MQTT response data
  useEffect(() => {
    const handleMqttData = async () => {
      let modalStatus = parseInt(localStorage.getItem('modalOpen'));
      if (allMqttData !== null && modalStatus !== 1) {
        //=== Update Customer Org Page Devices Data
        if (customerOrgDetails?.length > 0) {
          let updateCustData =
            ManageMqttData.updateCustomerOrgDevicesDataByMqtt(
              customerOrgDetails,
              allMqttData,
            );
          await setCustomerOrgData(updateCustData);
        }
        //=== Update Devices Data
        ManageMqttData.updateDeviceDataByMqtt(allMqttData, currentDeviceId);
        // dispatch(setMqttUpdateStatusFromResponse(false));
      }
    };
    handleMqttData();
  }, [allMqttData]);

  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
      .then((res) => {
        handleSiteLogout();
      });
  };

  /* On click submit button - send request to approve the customer accounts API */
  const handleSiteLogout = async (e) => {
    dispatch(clearWSSConnections());
    dispatch(clearRTCPeerConnections());
    dispatch(clearLiveStreams());
    dispatch(clearMQTTPeerConnections());
    dispatch(clearRemoteStreams());
    dispatch(resetPlatformInfo());
    // resetLiveViewDevices();
    localStorage.setItem('authenticated', false);
    localStorage.setItem('vmsAuthToken', null);
    localStorage.removeItem('tokenSend');
    localStorage.setItem('isSessionApiCall', false);
    localStorage.setItem('isAmplitudeSingleCall', false);
    await resetCustomerOrgData();
    resetNotificationData();
    resetCustomerOrgLocations();
    resetSelfAccount();
    keycloak.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
    });
    dispatch(resetSelfAccountData());
    resetLoggedInUserData();
    setPingApiCallTime(0);
    deleteRequest(areasFinal);
    if (allAreasStore) {
      deleteRequest(allAreasStore);
      dispatch(setAllAreas([]));
    }

    dispatch(setSubscribeAreasRequest(false));
    dispatch(setKeepAliveRequest(false));
    mqttDisconnectRequest();
  };

  useEffect(() => {
    setSelOrg(customerOrgDetails?.find((orgObj) => orgObj.orgId === orgId));
  }, [orgId, customerOrgDetails]);

  const isAppsIcon = () => {
    if (
      url !== '/customers/manage.html' &&
      url !== managePartnerUrl &&
      url !== '/organizationsettings.html' &&
      (loggedInUserData?.orgType === 'CUSTOMER_ORG' || orgId)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const createSession = (redirectUrl) => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const expireIn = keycloak?.tokenParsed?.exp - currentTime;
    const refreshExpireIn = keycloak?.refreshTokenParsed?.exp - currentTime;
    const reqBody = {
      expires_in: expireIn,
      refresh_expires_in: refreshExpireIn,
      refresh_token: keycloak?.refreshToken,
    };
    const config = {
      credentials: 'include',
      withCredentials: true,
      ...Utils.requestHeader(),
    };
    axios.post('user/session', reqBody, config).then((res) => {
      if (res.status === 200) {
        window.open(redirectUrl, '_blank');
      }
    });
  };
  const handleNavigation = async (appData) => {
    if (appData) {
      let redirectUrl = '';
      if (
        appData.id === constants.DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD &&
        (loggedInUserRole === roles.ROLE1PORTAL ||
          loggedInUserRole === roles.ROLE4PORTAL ||
          loggedInUserRole === roles.ROLE5PORTAL ||
          loggedInUserRole === roles.ROLE6PORTAL)
      ) {
        redirectUrl = `${appData?.url}/customers/dashboard.html?orgId=${
          orgId ? orgId : orgDetails?.orgId
        }&orgName=${orgName ? orgName : orgDetails?.orgName}`;
      } else {
        redirectUrl = `${appData?.url}?orgId=${
          orgId ? orgId : orgDetails?.orgId
        }`;
      }
      await createSession(redirectUrl);
    }
  };

  return (
    <>
      {showHeader && (
        <div className="position-sticky top-0" style={{ zIndex: 1020 }}>
          <TermConditionPolicyModal callback={handleSiteLogout} />
          <Navbar
            collapseOnSelect
            sticky="top"
            expand="lg"
            className="site-top-bar px-md-3 px-2 px-lg-5"
            style={{ backgroundColor: backgroundColor }}
          >
            <Container fluid>
              <Navbar.Brand>
                {process.env.REACT_APP_PROJECT ===
                AppDefaults.PROJECT_MEGATRON ? (
                  <>
                    <ShockwaveBrandLogoSvg />{' '}
                  </>
                ) : (
                  <BrandLogoSvg />
                )}
              </Navbar.Brand>
              {!showNavigation && (
                <>
                  <Nav className="me-auto site-start-links d-md-flex coud-portal-nav-title">
                    <span className="ms-2 me-1 text-white-50"> | </span>
                    <Nav.Link
                      onClick={() => {
                        if (loggedInUserData?.orgType === 'INSTALLER_ORG') {
                          navigate(customersUrl);
                        } else {
                          if (loggedInUserData?.accountStatus !== 'INACTIVE') {
                            navigate(
                              `/landing-page.html?orgId=${customerOrgDetails?.[0]?.orgId}&orgName=${customerOrgDetails?.[0]?.orgName}&fromPush=true`,
                            );
                          }
                        }
                      }}
                      className={`left-link align-self-center cloud-portal-style ${
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'inactive-user'
                      }`}
                      disabled={!customerOrgDetails?.[0]?.orgId}
                    >
                      <CloudPortalWhiteLogo />
                    </Nav.Link>
                  </Nav>
                  <Nav className="site-end-links mobile">
                    <NavDropdown
                      className="profile-nav right-link"
                      title={
                        <div className="profile-nav-icon">
                          <img alt="" src={headerMenu} />
                        </div>
                      }
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item
                        href="#"
                        className="logged-in-user right-link"
                      >
                        <div className="loggedin-user-as">
                          Signed in as
                          <div className="loggedin-user-title">{`${
                            loggedInUserData?.email
                              ? loggedInUserData?.email
                              : ''
                          }`}</div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`${
                          loggedInUserData?.accountStatus === 'INACTIVE' &&
                          'inactive-user-setting'
                        }`}
                        onClick={() =>
                          loggedInUserData?.accountStatus !== 'INACTIVE' &&
                          navigate('/accountsettings.html')
                        }
                      >
                        <span className="icon">
                          <HiOutlineUser />
                        </span>
                        {constants.TOP_HEADER_ACCOUNT_SETTINGS_NAV_TITLE}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`${
                          loggedInUserData?.accountStatus === 'INACTIVE' &&
                          'inactive-user-setting'
                        }`}
                        onClick={() =>
                          loggedInUserData?.accountStatus !== 'INACTIVE' &&
                          navigate('/organizationsettings.html')
                        }
                      >
                        <span className="icon">
                          <TbSettings />
                        </span>
                        {constants.TOP_HEADER_ORG_SETTINGS_NAV_TITLE}
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={invalidateSessionApi}>
                        <span className="icon">
                          <HiOutlineLogout />
                        </span>
                        {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav className="site-end-links desktop-header">
                    {loggedInUserData?.orgType === 'INSTALLER_ORG' &&
                      loggedInUserData?.accountStatus !==
                        constants.INACTIVE_USER_STATUS_LABEL.toUpperCase() &&
                      getLoggedInUserPolicies()?.view_other_account && (
                        <Nav.Link
                          onClick={() => {
                            navigate(managePartnerUrl);
                          }}
                          className={`right-link align-self-center ${
                            loggedInUserData?.accountStatus === 'INACTIVE' &&
                            'inactive-user'
                          }`}
                        >
                          <div className="fs-6">
                            <span className="d-none d-md-inline-block">
                              {constants.TOP_HEADER_PARTNER_USERS_NAV_TITLE}
                            </span>
                            <span className="d-inline-block d-md-none">
                              {constants.TOP_HEADER_PARTNER_USERS_NAV_TITLE}
                            </span>
                            <span className="ps-3 d-md-inline-block">
                              <BsChevronRight
                                style={{ height: 14, width: 14 }}
                              />
                            </span>
                          </div>
                        </Nav.Link>
                      )}
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body className="px-0">
                            <div className="top-container">
                              {appList?.map((item, index) => (
                                <Col
                                  key={`${index}-${item.name}`}
                                  xs={4}
                                  className="text-center app-list-style"
                                >
                                  <NavDropdown.Item
                                    onClick={() => handleNavigation(item)}
                                  >
                                    <div className="app-style">
                                      <img
                                        className="appIconImgHeight"
                                        src={item.wicon}
                                      />
                                    </div>
                                    <div className="appsMenuNameStyle">
                                      <span>{item.name}</span>
                                    </div>
                                  </NavDropdown.Item>
                                </Col>
                              ))}
                            </div>
                            <div className="bottom-container">
                              <NavDropdown.Item
                                onClick={() => {
                                  window.open(helpData?.portalCommon, '_blank');
                                }}
                              >
                                <div className="inner-container">
                                  <IoHelpCircleOutline
                                    size={24}
                                    color="#000000C4"
                                  />

                                  <span className="w-100">
                                    {constants.HELP}
                                  </span>
                                  <img
                                    src={greaterThanIcon}
                                    alt=""
                                    width={'24px'}
                                    height={'24px'}
                                  />
                                </div>
                              </NavDropdown.Item>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                      rootClose={true}
                      className="profile-nav right-link"
                      noArrow
                    >
                      <Nav.Link
                        onClick={() => getApps(`partner/apps`)}
                        className={`profile-nav-icon align-self-center ${
                          isAppsIcon() ? 'd-block' : 'd-none'
                        }`}
                      >
                        {loggedInUserData?.accountStatus !== 'INACTIVE' && (
                          <BsFillGrid3X3GapFill />
                        )}
                      </Nav.Link>
                    </OverlayTrigger>
                    {getLoggedInUserPolicies()?.view_cust_org_dashboard &&
                      (loggedInUserData?.orgType === 'CUSTOMER_ORG' ||
                        orgInfo?.orgId) && (
                        <Nav.Link
                          onClick={() => {
                            navigate(
                              `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
                            );
                          }}
                          className={`profile-nav-icon align-self-center ${
                            loggedInUserData?.accountStatus === 'INACTIVE' &&
                            'inactive-user'
                          } ${isAppsIcon() ? 'd-block' : 'd-none'}`}
                        >
                          <div className={`profile-nav-icon`}>
                            <HiOutlineOfficeBuilding size={20} />
                          </div>
                        </Nav.Link>
                      )}
                    <NavDropdown
                      className="profile-nav right-link"
                      title={
                        <div className="profile-nav-icon">
                          <LiaUserCircleSolid size={30} />
                        </div>
                      }
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item
                        href="#"
                        className="logged-in-user right-link"
                      >
                        <div className="loggedin-user-as">
                          Signed in as
                          <div className="loggedin-user-title">{`${
                            loggedInUserData?.email
                              ? loggedInUserData?.email
                              : ''
                          }`}</div>
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`${
                          location.pathname.startsWith('/accountsettings') &&
                          'active'
                        } ${
                          loggedInUserData?.accountStatus === 'INACTIVE' &&
                          'inactive-user-setting'
                        }`}
                        onClick={() =>
                          loggedInUserData?.accountStatus !== 'INACTIVE' &&
                          navigate('/accountsettings.html')
                        }
                      >
                        <span className="icon">
                          <HiOutlineUser />
                        </span>
                        {constants.TOP_HEADER_ACCOUNT_SETTINGS_NAV_TITLE}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`${
                          location.pathname.startsWith(
                            '/organizationsettings',
                          ) || location.pathname.startsWith('/editOrganization')
                            ? 'active'
                            : ''
                        } ${
                          loggedInUserData?.accountStatus === 'INACTIVE' &&
                          'inactive-user-setting'
                        }`}
                        onClick={() => {
                          if (loggedInUserData?.accountStatus !== 'INACTIVE') {
                            if (loggedInUserData?.orgType === 'CUSTOMER_ORG') {
                              navigate(
                                `/editOrganization.html?orgId=${loggedInUserData?.orgId}`,
                              );
                            } else {
                              navigate('/organizationsettings.html');
                            }
                          }
                        }}
                      >
                        <span className="icon">
                          <TbSettings />
                        </span>
                        {constants.TOP_HEADER_ORG_SETTINGS_NAV_TITLE}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={`${
                          location.pathname.startsWith('/updatepassword')
                            ? 'active'
                            : ''
                        } ${
                          loggedInUserData?.accountStatus === 'INACTIVE' &&
                          'inactive-user-setting'
                        }`}
                        onClick={() => navigate('/updatepassword.html')}
                      >
                        <span className="icon">
                          <HiOutlineLockClosed />
                        </span>
                        {constants.TOP_HEADER_UPDATE_PASSWORD_NAV_TITLE}
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={invalidateSessionApi}>
                        <span className="icon">
                          <HiOutlineLogout />
                        </span>
                        {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE}
                      </NavDropdown.Item>
                      <p className="version-info">
                        <span className="title">
                          {constants.RELEASE_VERSION_TITLE}
                        </span>
                        <span>{app_version}</span>
                      </p>
                    </NavDropdown>
                  </Nav>
                </>
              )}
            </Container>
          </Navbar>
          {!(
            (url === '/organizationsettings.html' ||
              url === '/updatepassword.html' ||
              url === '/accountsettings.html') &&
            loggedInUserData?.orgType === 'INSTALLER_ORG'
          ) &&
            url !== '/customers/dashboard.html' &&
            url !== '/managetechnicians.html' &&
            !isAccountScreen && (
              <Navbar expand="lg" className="sub-site-top-bar">
                {loggedInUserData?.orgType === 'INSTALLER_ORG' &&
                (url === '/customers/manage.html' ||
                  url === managePartnerUrl) ? (
                  <div className="container h-100 align-items-nav-header">
                    <div className="tab-btns" role="tablist">
                      {url === managePartnerUrl ? (
                        <button
                          className={`btn px-4 ms-0 ms-md-3 px-xl-5 tab-btn ${
                            url === managePartnerUrl && 'active'
                          }`}
                          data-target="tab-content-1"
                          onClick={() => navigate(managePartnerUrl)}
                        >
                          {' '}
                          {constants.TOP_HEADER_PARTNER_USERS_NAV_TITLE}
                        </button>
                      ) : (
                        <button
                          className={`btn ms-0 ms-md-3 px-4 px-xl-5 tab-btn ${
                            url === customersUrl && 'active'
                          }`}
                          data-target="tab-content-1"
                          onClick={() => navigate(customersUrl)}
                        >
                          {constants.TOP_HEADER_CUSTOMERS_NAV_TITLE}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="container h-100 justify-content-center">
                    <div
                      className={`tab-btns ${
                        loggedInUserData &&
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'inactive-user'
                      }`}
                      role="tablist"
                    >
                      {breadList.map((item, index) => (
                        <>
                          {item?.viewPolicy ? (
                            <button
                              key={`${index}-${item.title}`}
                              className={`btn px-4  px-xl-5 tab-btn ${
                                (url === item.activeUrl ||
                                  getUrl(item.activeUrl) === getUrl(url)) &&
                                'active'
                              }`}
                              data-target="tab-content-1"
                              onClick={() =>
                                loggedInUserData &&
                                loggedInUserData?.accountStatus !==
                                  'INACTIVE' &&
                                navigate(item.url)
                              }
                              disabled={!orgId && !orgInfo?.orgId}
                            >
                              <span
                                className={`d-inline-block pe-2 ${
                                  item.title ===
                                    constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE &&
                                  ''
                                }`}
                              >
                                {item.icon}
                              </span>
                              <span className="d-none d-md-inline-block">
                                {item.title}
                              </span>
                            </button>
                          ) : null}
                        </>
                      ))}
                    </div>
                  </div>
                )}
              </Navbar>
            )}
        </div>
      )}
    </>
  );
}

Header.defaultProps = {
  backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
    '--brand_black',
  ),
};

export default Header;
