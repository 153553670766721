import { useEffect, useRef, useState } from 'react';
import { PrimaryButton } from '../../components/common';
import { IoReload } from 'react-icons/io5';
import { constants } from '../../helpers';
import {
  DateFilterType,
  DeviceAppsTypes,
  LicenseStatusType,
  LicenseTypes,
} from '../../helpers/enums';
import { ReactComponent as RadioIcon } from '../../assets/images/Radio.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar-new.svg';
import moment from 'moment';
import { addDays } from 'date-fns';

const LicenseFilters = ({
  closeModal,
  fromDiscoveredDevices,
  onSetFiltersData,
  filtersData,
  onGetPosition,
  selectedDateRange,
  onSelectedDateRange,
  onChangeShowDateRangePicker,
  setShowReset,
  showReset,
  licenseNameData,
  showDateRangePicker,
  setShowDateRangePicker,
}) => {
  const license = [LicenseTypes.PAID.name, LicenseTypes.TRIAL.name];
  const licenseStatus = [
    LicenseStatusType.AVAILABLE.name,
    LicenseStatusType.ACTIVE.name,
    LicenseStatusType.EXPIRINGSOON.name,
    LicenseStatusType.EXPIRED.name,
  ];
  const today = new Date();
  const monthStart = new Date();
  monthStart.setHours(0, 0, 0, 0);
  monthStart.setDate(today.getDate() - 30);

  const todayNight = new Date();
  todayNight.setHours(23, 59, 59, 0);

  const defaultDate = {
    startDate: moment(new Date(monthStart).getTime()).format('MMM DD, YYYY'),
    endDate: moment(new Date(todayNight).getTime()).format('MMM DD, YYYY'),
  };

  const [filterDate, setFilterDate] = useState(
    filtersData?.dateFilterRange || defaultDate,
  );
  const [dateFilterUpdate, setDateFilterUpdate] = useState(
    filtersData?.dateFilterUpdate || 'all',
  );
  const [licenseUpdate, setLicenseUpdate] = useState(
    filtersData?.licenseUpdate,
  );
  const [licenseStatusUpdate, setLicenseStatusUpdate] = useState(
    filtersData?.licenseStatusUpdate,
  );
  const [applicationsLinked, setApplicationsLinked] = useState(
    filtersData?.applicationsLinked,
  );
  const dateRangeFieldRef = useRef(null);

  useEffect(() => {
    if (showReset) {
      onClickClear();
      setShowReset(false);
    }
  }, [showReset]);

  useEffect(() => {
    setFilterDate(selectedDateRange);
  }, [selectedDateRange]);

  const onClickOk = () => {
    const filtersData = {};
    filtersData.licenseUpdate = licenseUpdate;
    filtersData.licenseStatusUpdate = licenseStatusUpdate;
    filtersData.applicationsLinked = applicationsLinked;
    filtersData.dateFilterUpdate = dateFilterUpdate;
    if (filterDate?.startDate) {
      filtersData.startDate = new Date(filterDate.startDate).getTime();
    }

    if (filterDate?.endDate) {
      filtersData.endDate = new Date(filterDate.endDate).getTime();
    }
    if (dateFilterUpdate !== '') {
      filtersData.dateFilterRange = filterDate;
    }
    onSetFiltersData(filtersData);
    closeModal();
  };

  const handleClick = () => {
    if (dateRangeFieldRef.current) {
      const rect = dateRangeFieldRef.current.getBoundingClientRect();
      onGetPosition({
        top: rect.bottom - 160,
        left: rect.left - 425,
      });
    }
  };

  const onClickClear = () => {
    setLicenseUpdate([]);
    setLicenseStatusUpdate([]);
    setApplicationsLinked([]);
    setDateFilterUpdate('all');
  };

  const onChanegCheckBox = (e, id, name) => {
    let apps = [];
    if (licenseNameData?.length) {
      apps = licenseNameData?.map((license) => license?.sku);
    }

    const { checked } = e.target;
    let checkList = structuredClone(
      id === LicenseTypes.id
        ? licenseUpdate
        : id === LicenseStatusType.id
          ? licenseStatusUpdate
          : id === DeviceAppsTypes.id
            ? applicationsLinked
            : [],
    );
    if (checked && name) {
      checkList.push(name);
    } else if (name) {
      const index = checkList.indexOf(name);
      if (index > -1) {
        checkList.splice(index, 1);
      }
    }

    if (id === LicenseTypes.id && name) {
      setLicenseUpdate([...new Set(checkList)]);
    } else if (id === LicenseTypes.id && !name) {
      setLicenseUpdate(checked ? license : []);
    } else if (id === LicenseStatusType.id && name) {
      setLicenseStatusUpdate([...new Set(checkList)]);
    } else if (id === LicenseStatusType.id && !name) {
      setLicenseStatusUpdate(checked ? licenseStatus : []);
    } else if (id === DeviceAppsTypes.id && name) {
      setApplicationsLinked([...new Set(checkList)]);
    } else if (id === DeviceAppsTypes.id && !name) {
      setApplicationsLinked(checked ? apps : []);
    }
  };

  const getRowData = (details, id) => {
    const checkedStatus =
      id === LicenseTypes.id
        ? licenseUpdate.includes(details?.name)
        : id === LicenseStatusType.id
          ? licenseStatusUpdate.includes(details?.name)
          : id === DeviceAppsTypes.id
            ? applicationsLinked.includes(details?.name)
            : false;
    const requestObject = {
      target: {
        checked: !checkedStatus,
      },
    };
    return (
      <div
        className={`filter-row-container ${
          !details?.displayName ? 'hide' : ''
        }`}
        role="button"
        onClick={(e) => onChanegCheckBox(requestObject, id, details?.name)}
      >
        <div className="filter-checkbox">
          <input
            type="checkbox"
            checked={checkedStatus}
            onChange={(e) => onChanegCheckBox(e, id, details?.name)}
          />
        </div>
        {details?.displayName}
      </div>
    );
  };

  const handleChangeDate = (name) => {
    if (name === '') {
      setFilterDate({ startDate: '', endDate: '' });
      onSelectedDateRange({ startDate: '', endDate: '' });
      setDateFilterUpdate('');
      return;
    }
    let range = {
      startDate: moment(addDays(new Date(), -30)).format('MMM DD, YYYY'),
      endDate: moment(new Date()).format('MMM DD, YYYY'),
    };
    if (DateFilterType.EXPIRATIONDATE.name === name) {
      range = {
        startDate: moment(new Date()).format('MMM DD, YYYY'),
        endDate: moment(addDays(new Date(), 30)).format('MMM DD, YYYY'),
      };
    }
    setFilterDate(range);
    onSelectedDateRange(range);
    setDateFilterUpdate(name);
    onChangeShowDateRangePicker(false);
  };

  const getRadioBtnRowData = (details, id) => {
    return (
      <div
        className={`filter-row-container ${
          !details?.displayName ? 'hide' : ''
        }`}
        role="button"
        onClick={() => handleChangeDate(details?.name)}
      >
        <div className="filter-checkbox">
          <input
            type="radio"
            checked={dateFilterUpdate === details?.name}
            className="input-radio"
            name="dateFilter"
            onChange={() => handleChangeDate(details?.name)}
          />
          <RadioIcon className="custom-radio-icon" />
        </div>
        {details?.displayName}
      </div>
    );
  };

  const getRowDataForSKUs = (details, id, skuArray) => {
    const checkedStatus = skuArray.includes(details?.sku); // Check if the SKU is in the selected array
    const requestObject = {
      target: {
        checked: !checkedStatus,
      },
    };
    return (
      <div
        className={`filter-row-container ${
          !details?.displayName ? 'hide' : ''
        }`}
        role="button"
        onClick={() => onChanegCheckBox(requestObject, id, details?.sku)}
      >
        <div className="filter-checkbox">
          <input
            type="checkbox"
            checked={checkedStatus}
            onChange={() => onChanegCheckBox(requestObject, id, details?.sku)}
          />
        </div>
        <div>{details?.displayName}</div>
      </div>
    );
  };

  const renderFilterRowsForSKUs = () => {
    return licenseNameData.map((skuDetails) =>
      getRowDataForSKUs(skuDetails, DeviceAppsTypes.id, applicationsLinked),
    );
  };

  return (
    <>
      <div
        className="license-filter-modal-wrapper"
        onClick={() => {
          showDateRangePicker && setShowDateRangePicker(false);
        }}
      >
        <div className="filter-table-structure">
          {licenseNameData?.length ? (
            <div className="filter-column filter-column-border">
              <div className="filter-header">
                {constants.LICENSE_FILTER_LICENSE_NAME}
              </div>
              <div className="filter-container">
                <div
                  className={`filter-row-container`}
                  role="button"
                  onClick={() =>
                    onChanegCheckBox(
                      {
                        target: {
                          checked: !(
                            applicationsLinked?.length ===
                            licenseNameData?.length
                          ),
                        },
                      },
                      DeviceAppsTypes.id,
                    )
                  }
                >
                  <div className="filter-checkbox">
                    <input
                      type="checkbox"
                      checked={
                        applicationsLinked?.length === licenseNameData?.length
                      }
                      onChange={(e) => onChanegCheckBox(e, DeviceAppsTypes.id)}
                    />
                  </div>
                  {constants.LICENSE_FILTER_ALL}
                </div>
                {renderFilterRowsForSKUs()}
              </div>
              {/* <div className="filter-container">
              <div
                className={`filter-row-container`}
                role="button"
                onClick={() =>
                  onChanegCheckBox(
                    {
                      target: {
                        checked: !(applicationsLinked?.length === apps?.length),
                      },
                    },
                    DeviceAppsTypes.id
                  )
                }
              >
                <div className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={applicationsLinked?.length === apps?.length}
                    onChange={(e) => onChanegCheckBox(e, DeviceAppsTypes.id)}
                  />
                </div>
                {constants.LICENSE_FILTER_ALL}
              </div>
              {getRowData(DeviceAppsTypes.ONCLOUD, DeviceAppsTypes.id)}
              {getRowData(DeviceAppsTypes.SIGHTMIND, DeviceAppsTypes.id)}
              {getRowData(DeviceAppsTypes.DMPRO, DeviceAppsTypes.id)}
              {getRowData(DeviceAppsTypes.FLEXAI, DeviceAppsTypes.id)}
            </div> */}
            </div>
          ) : null}
          <div
            className={`filter-column ${
              !fromDiscoveredDevices ? 'filter-column-border' : ''
            }`}
          >
            <div className="filter-header">
              {constants.LICENSE_FILTER_LICENSE_TYPE}
            </div>
            <div className="filter-container">
              <div
                className={`filter-row-container`}
                role="button"
                onClick={() =>
                  onChanegCheckBox(
                    {
                      target: {
                        checked: !(licenseUpdate?.length === license?.length),
                      },
                    },
                    LicenseTypes.id,
                  )
                }
              >
                <div className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={licenseUpdate?.length === license?.length}
                    onChange={(e) => onChanegCheckBox(e, LicenseTypes.id)}
                  />
                </div>
                {constants.LICENSE_FILTER_ALL}
              </div>
              {getRowData(LicenseTypes.PAID, LicenseTypes.id)}
              {getRowData(LicenseTypes.TRIAL, LicenseTypes.id)}
            </div>
          </div>
          <div className="filter-column filter-column-border">
            <div className="filter-header">
              {constants.LICENSE_FILTER_STATUS}
            </div>
            <div className="filter-container">
              <div
                className={`filter-row-container`}
                role="button"
                onClick={() =>
                  onChanegCheckBox(
                    {
                      target: {
                        checked: !(
                          licenseStatusUpdate?.length === licenseStatus?.length
                        ),
                      },
                    },
                    LicenseStatusType.id,
                  )
                }
              >
                <div className="filter-checkbox">
                  <input
                    type="checkbox"
                    checked={
                      licenseStatusUpdate?.length === licenseStatus?.length
                    }
                    onChange={(e) => onChanegCheckBox(e, LicenseStatusType.id)}
                  />
                </div>
                {constants.LICENSE_FILTER_ALL}
              </div>
              {getRowData(LicenseStatusType.AVAILABLE, LicenseStatusType.id)}
              {getRowData(LicenseStatusType.ACTIVE, LicenseStatusType.id)}
              {getRowData(LicenseStatusType.EXPIRINGSOON, LicenseStatusType.id)}
              {getRowData(LicenseStatusType.EXPIRED, LicenseStatusType.id)}
            </div>
          </div>
          <div className="filter-column">
            <div className="filter-header">{constants.LICENSE_FILTER_DATE}</div>
            <div className="filter-container">
              <div
                className={`filter-row-container`}
                role="button"
                onClick={() => handleChangeDate('all')}
              >
                <div className="filter-checkbox">
                  <input
                    type="radio"
                    name="dateFilter"
                    className="input-radio"
                    checked={dateFilterUpdate === 'all'}
                    onChange={() => handleChangeDate('all')}
                  />
                  <RadioIcon className="custom-radio-icon" />
                </div>
                {constants.LICENSE_FILTER_ALL}
              </div>
              {getRadioBtnRowData(DateFilterType.ADDEDDATE, DateFilterType.id)}
              {getRadioBtnRowData(
                DateFilterType.ACTIVATIONDATE,
                DateFilterType.id,
              )}
              {getRadioBtnRowData(
                DateFilterType.EXPIRATIONDATE,
                DateFilterType.id,
              )}
              {dateFilterUpdate !== '' && dateFilterUpdate !== 'all' && (
                <div
                  ref={dateRangeFieldRef}
                  className="date-range-filter"
                  role="button"
                  // onClick={() => setShowDateRangePicker(true)}
                  onClick={handleClick}
                >
                  {filterDate?.startDate} - {filterDate?.endDate}
                  <CalendarIcon />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="filter-footer">
          <div
            className="left-section"
            role="button"
            onClick={() => onClickClear()}
          >
            <IoReload color="#0000007D" className="rotate-reload" />
            {constants.DEVICES_MODAL_CLEAR_ALL}
          </div>
          <div className="right-section">
            <PrimaryButton
              className="device-btn-list btn-primary"
              type="button"
              width="100px"
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              fontSize="14px"
              height="32px"
              fontWeight="400"
              borderRadius="4px"
              onClick={() => onClickOk()}
            >
              {constants.LICENSE_FILTER_OK_BUTTON}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default LicenseFilters;
