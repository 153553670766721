import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// TODO: See line 344 below.  Only delete this import if it will never be used at all
// import reportWebVitals from './reportWebVitals';
import megatron from './assets/css/megatron.scss';
import PrivateRoute from './auth/PrivateRoute';
import SignIn from './auth/SignIn';
import AccountVerify from './auth/AccountVerify';
import PageNotFound from './pages/PageNotFound';
import { withTranslation } from 'react-i18next';
import PartnerInvitation from './pages/organizations/PartnerInvitation';
import LandingPage from './pages/shockwave/LandingPage';
import {
  EditLocation,
  ManageLocations,
  NewLocation,
  Locations,
} from './pages/locations';
import { Products, ProductListing } from './pages/products';
import {
  ManageOrganizations as ManageCustomers,
  Organizations as Customers,
  EditOrganization as CustomerEditOrganization,
} from './pages/organizations';
import {
  Purchase,
  PurchaseConfirmation,
  PurchaseSummary,
} from './pages/purchase';
import { Licenses, ManageLicenses } from './pages/licenses';
import { Users, ManageUsers, NewUser } from './pages/users';
import { ManagePartnerUsers } from './pages/partnerUsers';
import { ManageTechnicians } from './pages/technicians';
import { Device, Devices, DeviceDashboard } from './pages/devices';
import {
  AccountSettings,
  OrganizationSettings,
  UpdatePassword,
} from './pages/settings';
import { AllEvents } from './pages/search';
import { AppDefaults, constants } from './helpers';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/App.scss';
import './assets/css/styles.scss';
import megatronFavicon from './assets/images/megatronFavicon.png';
import BrandFavicon from './assets/images/BrandFavicon.svg';
import { CameraDashboard, CameraList, Cameras } from './pages/cameras';

import { Utils } from './helpers/';
import NotificationDashboard from './pages/notifications/NotificationDashboard';
import { NotificationSetting } from './pages/notifications';
import OrganizationDetails from './pages/organizations/OrganizationDetails';
import { Provider } from 'react-redux';
import Store from './store/Store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './auth/Keycloak';
import { checkMQTTConnection } from './utils/connection/mqttConnection';
import { checkWSSConnection } from './utils/connection/wssConnection';
import {
  setMQTTConnection,
  setWSSConnection,
} from './store/reducers/StreamingReducer';
import colorCodes from './assets/css/themes';
import BecameAcustomer from './pages/marketing/BecameAcustomer';
import BecameApartner from './pages/marketing/BecameApartner';
import SuccessPartner from './pages/marketing/SuccessPartner';
import SuccessCustomer from './pages/marketing/SuccessCustomer';
import ChannelDetails from './pages/devices/listDevice/ChannelDetails';
import DiscoveredDevices from './pages/devices/listDevice/DiscoveredDevices';

const App = () => {
  const moduleStatus = Utils.getModuleStatus();
  let persistor = persistStore(Store);
  const onEvent = (event, error) => {
    if (typeof error !== 'undefined') {
      switch (event) {
        case 'onAuthSuccess':
          localStorage.setItem('authenticated', true);
          break;

        case 'onAuthLogout':
        case 'onTokenExpired':
          localStorage.setItem('authenticated', false);
          localStorage.setItem('vmsAuthToken', null);
          break;

        default:
      }
    }
    // If user is logged in - create mqtt connection
    if (!!keycloak.authenticated) {
      if (!checkMQTTConnection()) {
        Store.dispatch(setMQTTConnection(false));
      }
      if (!checkWSSConnection()) {
        Store.dispatch(setWSSConnection(false));
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('isSession', 0);
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      document.title = constants.PROJECT_PAGE_TITLE_MEGATRON;
      let link = document.querySelector("link[rel~='icon']");
      link.href = megatronFavicon;
    } else if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_DUCLO) {
      document.title = constants.PROJECT_PAGE_TITLE_DUCLO;
      let link = document.querySelector("link[rel~='icon']");
      link.href = BrandFavicon;
    }
    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      colorCodes.colors.map((colorCode) =>
        document.documentElement.style.setProperty(
          `--${colorCode}`,
          megatron[colorCode],
        ),
      );
    }
  }, []);
  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={onEvent}
          onTokens={({ token }) => {
            localStorage.setItem('vmsAuthToken', token);
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path="/accountVerify"
                element={
                  <PrivateRoute>
                    <AccountVerify />
                  </PrivateRoute>
                }
              />
              <Route
                path="/accountVerify/:invitationCode"
                element={
                  <PrivateRoute>
                    <AccountVerify />
                  </PrivateRoute>
                }
              />
              <Route
                path="/accountVerify.html/:invitationCode"
                element={
                  <PrivateRoute>
                    <AccountVerify />
                  </PrivateRoute>
                }
              />
              <Route
                path="/accountsettings.html"
                element={
                  <PrivateRoute>
                    <AccountSettings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/organizationsettings.html"
                element={
                  <PrivateRoute>
                    <OrganizationSettings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/updatepassword.html"
                element={
                  <PrivateRoute>
                    <UpdatePassword />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers"
                element={
                  <PrivateRoute>
                    <Customers />
                  </PrivateRoute>
                }
              >
                <Route
                  path="manage.html"
                  element={
                    <PrivateRoute>
                      <ManageCustomers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="dashboard.html"
                  element={
                    <PrivateRoute>
                      <OrganizationDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="editOrganization.html"
                  element={
                    <PrivateRoute>
                      <CustomerEditOrganization />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="editOrganization.html"
                element={
                  <PrivateRoute>
                    <CustomerEditOrganization />
                  </PrivateRoute>
                }
              />
              <Route
                path="licenses"
                element={
                  <PrivateRoute>
                    <Licenses />
                  </PrivateRoute>
                }
              >
                <Route
                  path="listing.html"
                  element={
                    <PrivateRoute>
                      <ManageLicenses />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="locations"
                element={
                  <PrivateRoute>
                    <Locations />
                  </PrivateRoute>
                }
              >
                <Route
                  path="manage.html"
                  element={
                    <PrivateRoute>
                      <ManageLocations />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="new.html"
                  element={
                    <PrivateRoute>
                      <NewLocation />
                    </PrivateRoute>
                  }
                  exact
                />
                <Route
                  path="edit.html:locationId"
                  element={
                    <PrivateRoute>
                      <EditLocation />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              >
                <Route
                  path="manage.html"
                  element={
                    <PrivateRoute>
                      <ManageUsers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="new.html"
                  element={
                    <PrivateRoute>
                      <NewUser />
                    </PrivateRoute>
                  }
                  exact
                />
              </Route>
              <Route
                path="products"
                element={
                  <PrivateRoute>
                    <Products />
                  </PrivateRoute>
                }
              >
                {moduleStatus?.PRODUCT_LISTING && (
                  <Route
                    path="listing.html"
                    element={
                      <PrivateRoute>
                        <ProductListing />
                      </PrivateRoute>
                    }
                  />
                )}
              </Route>
              {moduleStatus?.PRODUCT_LISTING && (
                <Route
                  path="purchase"
                  element={
                    <PrivateRoute>
                      <Purchase />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.PRODUCT_LISTING && (
                <Route
                  path="summary.html"
                  element={
                    <PrivateRoute>
                      <PurchaseSummary />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.PRODUCT_LISTING && (
                <Route
                  path="confirmation.html"
                  element={
                    <PrivateRoute>
                      <PurchaseConfirmation />
                    </PrivateRoute>
                  }
                />
              )}
              <Route
                path="devices"
                element={
                  <PrivateRoute>
                    <Devices />
                  </PrivateRoute>
                }
              >
                <Route
                  path="listing.html"
                  element={
                    <PrivateRoute>
                      <Device />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="dashboard.html"
                  element={
                    <PrivateRoute>
                      <DeviceDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="channel-details.html"
                  element={
                    <PrivateRoute>
                      <ChannelDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="discovered-devices.html"
                  element={
                    <PrivateRoute>
                      <DiscoveredDevices />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="cameras"
                element={
                  <PrivateRoute>
                    <Cameras />
                  </PrivateRoute>
                }
              >
                <Route
                  path="listing.html"
                  element={
                    <PrivateRoute>
                      <CameraList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="dashboard.html"
                  element={
                    <PrivateRoute>
                      <CameraDashboard />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="/" element={<SignIn />} />
              <Route
                path="/managepartnerusers.html"
                element={
                  <PrivateRoute>
                    <ManagePartnerUsers />
                  </PrivateRoute>
                }
              />
              <Route
                path="/managetechnicians.html"
                element={
                  <PrivateRoute>
                    <ManageTechnicians />
                  </PrivateRoute>
                }
              />

              {moduleStatus?.NOTIFICATION && (
                <Route
                  path="/notificationdashboard.html"
                  element={
                    <PrivateRoute>
                      <NotificationDashboard />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.NOTIFICATION && (
                <Route
                  path="/notificationsetting.html"
                  element={
                    <PrivateRoute>
                      <NotificationSetting />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.SEARCH_DASHBOARD && (
                <Route
                  path="/searchdashboard/searchevents.html"
                  element={
                    <PrivateRoute>
                      <AllEvents />
                    </PrivateRoute>
                  }
                />
              )}
              <Route
                path="/partnerinvite"
                element={<PartnerInvitation />}
              ></Route>
              <Route
                path="/landing-page.html"
                element={
                  <PrivateRoute>
                    <LandingPage />
                  </PrivateRoute>
                }
              ></Route>
              <Route path="/createpartnerorg" element={<BecameApartner />} />
              <Route path="/becomeacustomer" element={<BecameAcustomer />} />
              <Route path="/successpartner" element={<SuccessPartner />} />
              <Route path="/successcustomer" element={<SuccessCustomer />} />
              {/* <Route path="/live.html" element={<ComingSoon />} /> */}
              {/* <Route path="/incidents.html" element={<ComingSoon />} /> */}
              <Route path="/404.html" element={<PageNotFound />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </ReactKeycloakProvider>
      </PersistGate>
    </Provider>
  );
};

export default withTranslation()(App);
